import { graphqlClient } from "@/apollo";
import {
  GetDepartmentListDocument,
  type GetDepartmentListQuery,
} from "@/graphql/generated-types";

const getDepartments = async (): Promise<GetDepartmentListQuery> => {
  const response = await graphqlClient.query({
    query: GetDepartmentListDocument,
    fetchPolicy: "no-cache",
  });

  if (response.data.institutions?.getMyInstitution.departments) {
    return response.data;
  }

  throw new Error("Query error on getEmployees");
};

export default {
  getDepartments,
};
