/**
 * THIS IS GENERATED FILE. DO NOT MODIFY IT DIRECTLY, RUN 'npm run types' INSTEAD.
 */
import type { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Color: { input: any; output: any };
  Cursor: { input: any; output: any };
  Date: { input: any; output: any };
  DateTime: { input: any; output: any };
  Time: { input: any; output: any };
  TimeZone: { input: any; output: any };
};

export enum Approval_State {
  Approved = "APPROVED",
  Needed = "NEEDED",
  Private = "PRIVATE",
  Rejected = "REJECTED",
  Unneeded = "UNNEEDED",
}

export enum App_Id {
  IbgNok = "IBG_NOK",
  IbgNotifications = "IBG_NOTIFICATIONS",
  IbgVideocall = "IBG_VIDEOCALL",
}

export enum Audit_Event_Entity {
  PlannerRepeatTask = "PLANNER_REPEAT_TASK",
  PlannerSubtask = "PLANNER_SUBTASK",
  PlannerTask = "PLANNER_TASK",
}

export enum Audit_Event_Type {
  Approve = "APPROVE",
  Complete = "COMPLETE",
  Create = "CREATE",
  Delete = "DELETE",
  Reject = "REJECT",
  Uncomplete = "UNCOMPLETE",
  Update = "UPDATE",
}

export type AccessToken = {
  __typename?: "AccessToken";
  id: Scalars["ID"]["output"];
  ttl: Scalars["String"]["output"];
  user?: Maybe<User>;
};

export type Activity = RepeatingActivity | SingleActivity;

export type ActivityCalendar = {
  __typename?: "ActivityCalendar";
  date: Scalars["Date"]["output"];
  empty?: Maybe<Scalars["Boolean"]["output"]>;
  participating?: Maybe<Scalars["Boolean"]["output"]>;
};

export type ActivityInput = {
  activityClub?: InputMaybe<Scalars["ID"]["input"]>;
  departments?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  duration?: InputMaybe<Scalars["Int"]["input"]>;
  employeesResponsible?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  employeesSignedUp?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /** @deprecated Calculated using duration */
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  groups?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  image?: InputMaybe<Scalars["ID"]["input"]>;
  institution?: InputMaybe<Scalars["ID"]["input"]>;
  isRepeat?: InputMaybe<Scalars["Boolean"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  maxSignup?: InputMaybe<Scalars["Int"]["input"]>;
  notifyNokOnSignup?: InputMaybe<Scalars["Boolean"]["input"]>;
  pdf?: InputMaybe<Scalars["ID"]["input"]>;
  pictogram?: InputMaybe<Scalars["ID"]["input"]>;
  pictures?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  price?: InputMaybe<Scalars["Int"]["input"]>;
  repeat?: InputMaybe<Scalars["ID"]["input"]>;
  residentsResponsible?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  residentsSignedUp?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  rrule?: InputMaybe<Scalars["String"]["input"]>;
  /** @deprecated Is always the same as endDate */
  showDateEnd?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** @deprecated Use showFrom instead */
  showDateStart?: InputMaybe<Scalars["DateTime"]["input"]>;
  showFrom?: InputMaybe<Scalars["Int"]["input"]>;
  signup?: InputMaybe<Scalars["Boolean"]["input"]>;
  signupClose?: InputMaybe<Scalars["Int"]["input"]>;
  /** @deprecated use signupClose instead */
  signupEnd?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** @deprecated Not used */
  signupStart?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updateGroupPosts?: InputMaybe<Scalars["Boolean"]["input"]>;
  vacation?: InputMaybe<Scalars["Boolean"]["input"]>;
  videos?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type ActivityMutation = {
  __typename?: "ActivityMutation";
  delete?: Maybe<DefaultResponse>;
  save?: Maybe<DefaultResponse>;
  saveClub?: Maybe<DefaultResponse>;
  saveNotificationSettings?: Maybe<DefaultResponse>;
};

export type ActivityMutationDeleteArgs = {
  id: Scalars["ID"]["input"];
};

export type ActivityMutationSaveArgs = {
  activity: ActivityInput;
  handleRepeats?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ActivityMutationSaveClubArgs = {
  club: ClubInput;
};

export type ActivityMutationSaveNotificationSettingsArgs = {
  settings: NotificationSettingsInput;
};

export type ActivityQuery = {
  __typename?: "ActivityQuery";
  getActivities?: Maybe<Array<Activity>>;
  getActivitiesByFilters?: Maybe<Array<Activity>>;
  getClubs?: Maybe<Array<Club>>;
};

export type ActivityQueryGetActivitiesArgs = {
  ids: Array<Scalars["ID"]["input"]>;
};

export type ActivityQueryGetActivitiesByFiltersArgs = {
  dateFrom: Scalars["DateTime"]["input"];
  dateTo: Scalars["DateTime"]["input"];
  filter: Filter;
  visibility?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ActivityQueryGetClubsArgs = {
  institutionId: Scalars["ID"]["input"];
};

export type ActivitySubscription = {
  id: Scalars["ID"]["input"];
};

export type Address = {
  __typename?: "Address";
  city?: Maybe<Scalars["String"]["output"]>;
  postalCode?: Maybe<Scalars["String"]["output"]>;
  street?: Maybe<Scalars["String"]["output"]>;
};

export type App = AppExternalActivity;

export type AppExternalActivity = {
  __typename?: "AppExternalActivity";
  icon: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  image?: Maybe<Picture>;
  institution: Institution;
  /** @deprecated Use `institution` entity instead */
  institutionId: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export type AppExternalActivityInput = {
  departmentId?: InputMaybe<Scalars["ID"]["input"]>;
  fromInstitutionId?: InputMaybe<Scalars["ID"]["input"]>;
  icon?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  institutionId?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  resourceId?: InputMaybe<Scalars["ID"]["input"]>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AppMutation = {
  __typename?: "AppMutation";
  deleteAppConnectionsScreen?: Maybe<DefaultResponse>;
  saveAppConnectionsScreen?: Maybe<DefaultResponse>;
};

export type AppMutationDeleteAppConnectionsScreenArgs = {
  id: Scalars["ID"]["input"];
};

export type AppMutationSaveAppConnectionsScreenArgs = {
  app: AppExternalActivityInput;
};

export type Archive = {
  __typename?: "Archive";
  documents?: Maybe<Array<Document>>;
  galleries?: Maybe<Array<Gallery>>;
  pictures?: Maybe<Array<GalleryPicture>>;
  videos?: Maybe<Array<GalleryVideo>>;
};

export type AuditLog = Node & {
  __typename?: "AuditLog";
  created: Scalars["DateTime"]["output"];
  entityId: Scalars["ID"]["output"];
  entityType: Scalars["String"]["output"];
  event: Audit_Event_Type;
  id: Scalars["ID"]["output"];
  profileId?: Maybe<Scalars["ID"]["output"]>;
  profileType?: Maybe<Profile_Type>;
  userId: Scalars["ID"]["output"];
};

export type AuditLogFilter = {
  end?: InputMaybe<Scalars["DateTime"]["input"]>;
  entityId?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  entityType?: InputMaybe<Audit_Event_Entity>;
  event?: InputMaybe<Audit_Event_Type>;
  start?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AuditLogQuery = {
  __typename?: "AuditLogQuery";
  getByFilters: Array<AuditLog>;
};

export type AuditLogQueryGetByFiltersArgs = {
  filters?: InputMaybe<AuditLogFilter>;
};

export enum Booking_Authentication {
  EmployeeOnly = "EMPLOYEE_ONLY",
  None = "NONE",
  Password = "PASSWORD",
}

export enum Booking_Slot_State {
  Available = "AVAILABLE",
  Booked = "BOOKED",
  Expired = "EXPIRED",
  Locked = "LOCKED",
}

export enum Bump_Type {
  Commented = "COMMENTED",
  EditedPost = "EDITED_POST",
  NewPost = "NEW_POST",
}

export type BookingInput = {
  bookingResourceId: Scalars["ID"]["input"];
  end: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  institutionId: Scalars["ID"]["input"];
  isRepeat?: InputMaybe<Scalars["Boolean"]["input"]>;
  repeatId?: InputMaybe<Scalars["ID"]["input"]>;
  start: Scalars["DateTime"]["input"];
  status: Scalars["Int"]["input"];
  title: Scalars["String"]["input"];
};

export type BookingMutation = {
  __typename?: "BookingMutation";
  bookResourceScreen?: Maybe<DefaultResponse>;
  bookSlottedResourceScreen?: Maybe<DefaultResponse>;
  cancelBooking?: Maybe<DefaultResponse>;
  cancelBookingScreen?: Maybe<DefaultResponse>;
  cancelSlottedBookingScreen?: Maybe<DefaultResponse>;
  deleteBooking?: Maybe<DefaultResponse>;
  deleteBookingResource?: Maybe<DefaultResponse>;
  saveBooking?: Maybe<DefaultResponse>;
  saveNotificationSettings?: Maybe<DefaultResponse>;
};

export type BookingMutationBookResourceScreenArgs = {
  booking: BookingResourceBooking;
  participants: Array<ProfileInput>;
};

export type BookingMutationBookSlottedResourceScreenArgs = {
  bookingSlot: BookingResourceBookingSlot;
  participants: Array<ProfileInput>;
};

export type BookingMutationCancelBookingArgs = {
  bookingResourceBookingId: Scalars["ID"]["input"];
};

export type BookingMutationCancelBookingScreenArgs = {
  bookingResourceBookingId: Scalars["ID"]["input"];
  profile: ScreenProfile;
};

export type BookingMutationCancelSlottedBookingScreenArgs = {
  bookingSlot: BookingResourceBookingSlot;
  profile: ScreenProfile;
};

export type BookingMutationDeleteBookingArgs = {
  id: Scalars["ID"]["input"];
};

export type BookingMutationDeleteBookingResourceArgs = {
  id: Scalars["ID"]["input"];
};

export type BookingMutationSaveBookingArgs = {
  booking: BookingInput;
  participants?: InputMaybe<Array<ProfileInput>>;
};

export type BookingMutationSaveNotificationSettingsArgs = {
  settings: NotificationSettingsInput;
};

export type BookingResource = Node & {
  __typename?: "BookingResource";
  authentication: Booking_Authentication;
  bookings?: Maybe<Array<BookingSlot>>;
  coverImage?: Maybe<Picture>;
  departments?: Maybe<Array<Department>>;
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  institution: Institution;
  institutions?: Maybe<Array<Institution>>;
  slots?: Maybe<Array<BookingSlot>>;
  slotted: Scalars["Boolean"]["output"];
  title: Scalars["String"]["output"];
};

export type BookingResourceSlotsArgs = {
  timezone?: Scalars["TimeZone"]["input"];
};

export type BookingResourceBooking = {
  bookingResourceId: Scalars["ID"]["input"];
  end: Scalars["DateTime"]["input"];
  start: Scalars["DateTime"]["input"];
  title: Scalars["String"]["input"];
};

export type BookingResourceBookingSlot = {
  bookingResourceId: Scalars["ID"]["input"];
  bookingResourceSlotId: Scalars["ID"]["input"];
  date: Scalars["Date"]["input"];
};

export type BookingResourceCalendar = {
  __typename?: "BookingResourceCalendar";
  date: Scalars["Date"]["output"];
  empty: Scalars["Boolean"]["output"];
  /** @deprecated Times are no longer relevant */
  timeConflict?: Maybe<Scalars["Boolean"]["output"]>;
};

export type BookingSlot = Node & {
  __typename?: "BookingSlot";
  description?: Maybe<Scalars["String"]["output"]>;
  end: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  participants?: Maybe<Array<Profile>>;
  participating: Scalars["Boolean"]["output"];
  resource: BookingResource;
  start: Scalars["DateTime"]["output"];
  state: Booking_Slot_State;
};

export enum Call_Status {
  Accepted = "ACCEPTED",
  Declined = "DECLINED",
  Finished = "FINISHED",
  Ringing = "RINGING",
  Unanswered = "UNANSWERED",
}

export enum Container_Type {
  Activity = "ACTIVITY",
  Agenda = "AGENDA",
  Archive = "ARCHIVE",
  Booking = "BOOKING",
  Club = "CLUB",
  CoreNews = "CORE_NEWS",
  Gallery = "GALLERY",
  Group = "GROUP",
  Institution = "INSTITUTION",
  Logo = "LOGO",
  Meal = "MEAL",
  MealDish = "MEAL_DISH",
  News = "NEWS",
  Pdf = "PDF",
  People = "PEOPLE",
  Pictogram = "PICTOGRAM",
  Planner = "PLANNER",
}

export type Call = {
  __typename?: "Call";
  createdAt: Scalars["DateTime"]["output"];
  endedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  initiator: User;
  participants: Array<User>;
  roomToken: Scalars["String"]["output"];
  status: Call_Status;
};

export type CallMutation = {
  __typename?: "CallMutation";
  accept: CallResponse;
  decline?: Maybe<Call>;
  finish?: Maybe<Call>;
  initiate: CallResponse;
};

export type CallMutationAcceptArgs = {
  id: Scalars["ID"]["input"];
};

export type CallMutationDeclineArgs = {
  id: Scalars["ID"]["input"];
};

export type CallMutationFinishArgs = {
  id: Scalars["ID"]["input"];
};

export type CallMutationInitiateArgs = {
  participants: Array<Scalars["ID"]["input"]>;
};

export type CallResponse = {
  __typename?: "CallResponse";
  accessToken: Scalars["String"]["output"];
  call: Call;
};

export type Club = Node & {
  __typename?: "Club";
  color?: Maybe<Scalars["Color"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  logo: Picture;
  name: Scalars["String"]["output"];
};

export type ClubInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  institution?: InputMaybe<Scalars["ID"]["input"]>;
  logo?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Comment = Node & {
  __typename?: "Comment";
  author?: Maybe<Profile>;
  body: Scalars["String"]["output"];
  changed: Scalars["DateTime"]["output"];
  coverImage?: Maybe<Picture>;
  id: Scalars["ID"]["output"];
  isChanged: Scalars["Boolean"]["output"];
  reactions?: Maybe<Array<Reaction>>;
  time: Scalars["DateTime"]["output"];
};

export type ContactMutation = {
  __typename?: "ContactMutation";
  favorite?: Maybe<DefaultResponse>;
  unfavorite?: Maybe<DefaultResponse>;
};

export type ContactMutationFavoriteArgs = {
  id: Scalars["ID"]["input"];
  type: Profile_Type;
};

export type ContactMutationUnfavoriteArgs = {
  id: Scalars["ID"]["input"];
  type: Profile_Type;
};

export enum Day_Of_Week {
  Friday = "FRIDAY",
  Monday = "MONDAY",
  Saturday = "SATURDAY",
  Sunday = "SUNDAY",
  Thursday = "THURSDAY",
  Tuesday = "TUESDAY",
  Wednesday = "WEDNESDAY",
}

export enum Device_Environment {
  Debug = "DEBUG",
  Production = "PRODUCTION",
}

export enum Device_Token_Type {
  Apns = "APNS",
  ApnsVoip = "APNS_VOIP",
  Firebase = "FIREBASE",
}

export type DefaultResponse = {
  __typename?: "DefaultResponse";
  id?: Maybe<Scalars["ID"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type DeleteDepartmentResponse = {
  __typename?: "DeleteDepartmentResponse";
  dependencies?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  success: Scalars["Boolean"]["output"];
};

export type DeletePlannerTaskInput = {
  deleteCompleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  deleteType?: InputMaybe<Planner_Task_Delete_Type>;
  id: Scalars["ID"]["input"];
};

export type Department = Node & {
  __typename?: "Department";
  color?: Maybe<Scalars["Color"]["output"]>;
  employeeOnly: Scalars["Boolean"]["output"];
  employees?: Maybe<Array<Employee>>;
  /** @deprecated does not exist on department */
  icon?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  institutionId: Scalars["ID"]["output"];
  loginKey?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  presentation?: Maybe<DepartmentPresentation>;
  screenOnly: Scalars["Boolean"]["output"];
  settings?: Maybe<DepartmentSettings>;
  shiftType?: Maybe<Shift_Type>;
  showShifts: Scalars["Boolean"]["output"];
};

export type DepartmentAppConfig = {
  __typename?: "DepartmentAppConfig";
  /** @deprecated Use modulesList instead */
  modules?: Maybe<Array<Maybe<ModuleForApp>>>;
  modulesList?: Maybe<Array<Module_Type>>;
};

export type DepartmentAppConfigInput = {
  modules?: InputMaybe<Array<InputMaybe<Module_Type>>>;
};

export type DepartmentExternalServices = {
  __typename?: "DepartmentExternalServices";
  vismaGatWsId?: Maybe<Scalars["ID"]["output"]>;
};

export type DepartmentExternalServicesInput = {
  vismaGatWsId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type DepartmentInput = {
  color?: InputMaybe<Scalars["Color"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  employeeOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  institution?: InputMaybe<Scalars["ID"]["input"]>;
  keycloakMapping?: InputMaybe<Scalars["String"]["input"]>;
  loginKey?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  settings?: InputMaybe<DepartmentSettingsInput>;
  showShifts?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DepartmentMealSettings = {
  __typename?: "DepartmentMealSettings";
  daysForward: Scalars["Int"]["output"];
  nextDay: Scalars["Time"]["output"];
  /** @deprecated Moved to institution level */
  signup: Scalars["Boolean"]["output"];
};

export type DepartmentMutation = {
  __typename?: "DepartmentMutation";
  save?: Maybe<DefaultResponse>;
};

export type DepartmentMutationSaveArgs = {
  department: DepartmentInput;
};

export type DepartmentPresentation = {
  __typename?: "DepartmentPresentation";
  description?: Maybe<Scalars["String"]["output"]>;
  image: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type DepartmentQuery = {
  __typename?: "DepartmentQuery";
  getExternalDepartments?: Maybe<Array<Department>>;
};

export type DepartmentQueryGetExternalDepartmentsArgs = {
  institutionId: Scalars["ID"]["input"];
  type: Module_List;
};

export type DepartmentScreenConfig = {
  __typename?: "DepartmentScreenConfig";
  carouselDuration?: Maybe<Scalars["Int"]["output"]>;
  departmentSwitcher: Scalars["Boolean"]["output"];
  footerConfig?: Maybe<Array<Maybe<Module_List>>>;
  gridSize?: Maybe<Scalars["Int"]["output"]>;
  modules?: Maybe<Array<Maybe<ModuleForScreen>>>;
  timeout?: Maybe<Scalars["Int"]["output"]>;
  tokenTTL?: Maybe<Scalars["Int"]["output"]>;
};

export type DepartmentScreenConfigInput = {
  departmentSwitcher?: InputMaybe<Scalars["Boolean"]["input"]>;
  footerConfig?: InputMaybe<Array<InputMaybe<Module_List>>>;
  gridSize?: InputMaybe<Scalars["Int"]["input"]>;
  modules?: InputMaybe<Array<InputMaybe<ModuleForScreenInput>>>;
  tokenTTL?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DepartmentSettings = {
  __typename?: "DepartmentSettings";
  appConfig?: Maybe<DepartmentAppConfig>;
  externalServices?: Maybe<DepartmentExternalServices>;
  meal?: Maybe<DepartmentMealSettings>;
  screenConfig?: Maybe<DepartmentScreenConfig>;
  shift?: Maybe<DepartmentShiftSettings>;
  simpleSchedulePlan?: Maybe<Array<SimpleSchedulePlanEntry>>;
  slideshow?: Maybe<DepartmentSlideshowSettings>;
};

export type DepartmentSettingsInput = {
  appConfig?: InputMaybe<DepartmentAppConfigInput>;
  externalServices?: InputMaybe<DepartmentExternalServicesInput>;
  screenConfig?: InputMaybe<DepartmentScreenConfigInput>;
};

export type DepartmentShiftSettings = {
  __typename?: "DepartmentShiftSettings";
  dayDisplay?: Maybe<Scalars["Int"]["output"]>;
  daysForward?: Maybe<Scalars["Int"]["output"]>;
  gfx?: Maybe<Scalars["Boolean"]["output"]>;
  showCancelledFp?: Maybe<Scalars["Boolean"]["output"]>;
};

export type DepartmentSlideshowSettings = {
  __typename?: "DepartmentSlideshowSettings";
  duration?: Maybe<Scalars["Int"]["output"]>;
};

export type DeviceInput = {
  appId: App_Id;
  environment?: InputMaybe<Device_Environment>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  token: Scalars["String"]["input"];
  tokenType?: InputMaybe<Device_Token_Type>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
  version?: InputMaybe<Scalars["String"]["input"]>;
};

export type DeviceMutation = {
  __typename?: "DeviceMutation";
  deregister?: Maybe<DefaultResponse>;
  register?: Maybe<DefaultResponse>;
};

export type DeviceMutationDeregisterArgs = {
  appId?: InputMaybe<App_Id>;
  token: Scalars["String"]["input"];
};

export type DeviceMutationRegisterArgs = {
  input?: InputMaybe<DeviceInput>;
};

export type Document = Node & {
  __typename?: "Document";
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  title: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
};

export type DocumentConnection = {
  __typename?: "DocumentConnection";
  edges: Array<DocumentEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]["output"]>;
};

export type DocumentEdge = {
  __typename?: "DocumentEdge";
  cursor: Scalars["Cursor"]["output"];
  node: Document;
};

export enum Entity_Type {
  Activity = "ACTIVITY",
  Gallery = "GALLERY",
  Library = "LIBRARY",
  News = "NEWS",
  Repeat = "REPEAT",
}

export type Employee = Node & {
  __typename?: "Employee";
  alias?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Does not exist on Employee */
  birthday?: Maybe<Scalars["Date"]["output"]>;
  departments?: Maybe<Array<Department>>;
  description?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use image instead */
  displayImage: Scalars["String"]["output"];
  /** @deprecated Use image instead */
  displayImageId?: Maybe<Scalars["ID"]["output"]>;
  displayName: Scalars["String"]["output"];
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  hasAccess: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  image?: Maybe<Picture>;
  institution: Institution;
  isFavorite?: Maybe<Scalars["Boolean"]["output"]>;
  isSuperuser?: Maybe<Scalars["Boolean"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  mobile?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  password?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  profileVideo?: Maybe<Video>;
  settings?: Maybe<ProfileSettings>;
  /** @deprecated Does not exist */
  shift?: Maybe<Shift>;
  /** @deprecated Does not exist on Employee */
  showBirthday?: Maybe<Scalars["Boolean"]["output"]>;
  showEmail?: Maybe<Scalars["Boolean"]["output"]>;
  showImage?: Maybe<Scalars["Boolean"]["output"]>;
  showLastName?: Maybe<Scalars["Boolean"]["output"]>;
  showMobile?: Maybe<Scalars["Boolean"]["output"]>;
  startScreen?: Maybe<Start_Screen>;
  statistics?: Maybe<ProfileStats>;
  surname?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  type: Profile_Type;
  user?: Maybe<User>;
  /** @deprecated Use settings {videoCall} instead */
  videoCallEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  /** @deprecated Use settings {videoCall} instead */
  videoCallStatus?: Maybe<Video_Call_Status>;
};

export type EmployeeInput = {
  alias?: InputMaybe<Scalars["String"]["input"]>;
  departments?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstname?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  image?: InputMaybe<Scalars["ID"]["input"]>;
  institution?: InputMaybe<Scalars["ID"]["input"]>;
  lastname?: InputMaybe<Scalars["String"]["input"]>;
  mobile?: InputMaybe<Scalars["String"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  profileVideo?: InputMaybe<Scalars["ID"]["input"]>;
  settings?: InputMaybe<EmployeeSettingsInput>;
  showEmail?: InputMaybe<Scalars["Boolean"]["input"]>;
  showImage?: InputMaybe<Scalars["Boolean"]["input"]>;
  showLastname?: InputMaybe<Scalars["Boolean"]["input"]>;
  showMobile?: InputMaybe<Scalars["Boolean"]["input"]>;
  startScreen?: InputMaybe<Start_Screen>;
  user?: InputMaybe<Scalars["ID"]["input"]>;
  video?: InputMaybe<Scalars["ID"]["input"]>;
};

export type EmployeeSettingsInput = {
  vismaGatId: Scalars["ID"]["input"];
};

export type EmployeeTitle = {
  __typename?: "EmployeeTitle";
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  title: Scalars["String"]["output"];
};

export type EmployeeTitleInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  institution?: InputMaybe<Scalars["ID"]["input"]>;
  status?: InputMaybe<Scalars["Boolean"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type EventEntity =
  | BookingSlot
  | Meal
  | RepeatingActivity
  | RepeatingMeeting
  | RepeatingPlannerTask
  | SingleActivity
  | SingleMeeting
  | SinglePlannerTask;

export type EventPrice = {
  __typename?: "EventPrice";
  currency: Scalars["String"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<Scalars["Int"]["output"]>;
  profileType: Profile_Type;
};

export type ExternalServicesQuery = {
  __typename?: "ExternalServicesQuery";
  getTravelPlan?: Maybe<Array<Maybe<TravelPlan>>>;
  searchTravelPlanLocation?: Maybe<Array<Maybe<TravelPlanLocation>>>;
};

export type ExternalServicesQueryGetTravelPlanArgs = {
  id: Scalars["ID"]["input"];
};

export type ExternalServicesQuerySearchTravelPlanLocationArgs = {
  locationName: Scalars["String"]["input"];
};

export type Filter = {
  departments?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  groups?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  institutions?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export enum Gallery_Media_Type {
  Image = "IMAGE",
  Video = "VIDEO",
}

export enum Grouptype {
  RelativeDepartment = "RELATIVE_DEPARTMENT",
  RelativeResident = "RELATIVE_RESIDENT",
  Standard = "STANDARD",
}

export enum Group_Connection {
  Activity = "ACTIVITY",
  Booking = "BOOKING",
  Document = "DOCUMENT",
  Gallery = "GALLERY",
  MealPlan = "MEAL_PLAN",
  News = "NEWS",
  SchedulePlan = "SCHEDULE_PLAN",
}

export enum Group_Post_Entity_Type {
  Activity = "ACTIVITY",
  Gallery = "GALLERY",
  Library = "LIBRARY",
  Meal = "MEAL",
  Meeting = "MEETING",
  News = "NEWS",
  Post = "POST",
}

export enum Group_Type {
  Connect = "CONNECT",
  RelativeDepartment = "RELATIVE_DEPARTMENT",
  RelativeResident = "RELATIVE_RESIDENT",
  Standard = "STANDARD",
}

export type Gallery = Node & {
  __typename?: "Gallery";
  category?: Maybe<GalleryCategory>;
  coverImage: Picture;
  departments?: Maybe<Array<Department>>;
  groups?: Maybe<Array<Group>>;
  id: Scalars["ID"]["output"];
  image?: Maybe<Picture>;
  institution: Institution;
  media?: Maybe<Array<GalleryMedia>>;
  pictures?: Maybe<Array<GalleryPicture>>;
  settings?: Maybe<GallerySettings>;
  showOnFrontpage: Scalars["Boolean"]["output"];
  title: Scalars["String"]["output"];
  videos?: Maybe<Array<GalleryVideo>>;
};

export type GalleryCategory = Node & {
  __typename?: "GalleryCategory";
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  title: Scalars["String"]["output"];
};

export type GalleryInput = {
  category?: InputMaybe<Scalars["ID"]["input"]>;
  departments?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  groups?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  image?: InputMaybe<Scalars["ID"]["input"]>;
  institution?: InputMaybe<Scalars["ID"]["input"]>;
  pictures?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  residents?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  showOnFrontpage?: InputMaybe<Scalars["Boolean"]["input"]>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  videos?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type GalleryMedia = GalleryPicture | GalleryVideo;

export type GalleryMutation = {
  __typename?: "GalleryMutation";
  delete?: Maybe<DefaultResponse>;
  save?: Maybe<DefaultResponse>;
};

export type GalleryMutationDeleteArgs = {
  id: Scalars["ID"]["input"];
};

export type GalleryMutationSaveArgs = {
  gallery: GalleryInput;
};

export type GalleryPicture = Node & {
  __typename?: "GalleryPicture";
  blurhash?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  favorited?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["ID"]["output"];
  inMyArchive?: Maybe<Scalars["Boolean"]["output"]>;
  source: Scalars["String"]["output"];
  type: Gallery_Media_Type;
};

export type GallerySettings = {
  __typename?: "GallerySettings";
  dreambrokerUrl?: Maybe<Scalars["String"]["output"]>;
};

export type GalleryVideo = Node & {
  __typename?: "GalleryVideo";
  blurhash?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  favorited?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["ID"]["output"];
  provider?: Maybe<Video_Provider>;
  thumbnail?: Maybe<Scalars["String"]["output"]>;
  type: Gallery_Media_Type;
  url: Scalars["String"]["output"];
};

export type Group = Node & {
  __typename?: "Group";
  color?: Maybe<Scalars["Color"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  documentsConnection: DocumentConnection;
  externalDescription?: Maybe<Scalars["String"]["output"]>;
  groupType: Grouptype;
  id: Scalars["ID"]["output"];
  logo: Picture;
  moduleTypes: Array<Module_Type>;
  name: Scalars["String"]["output"];
  participants?: Maybe<Array<Profile>>;
  pictures?: Maybe<Array<Picture>>;
  /** @deprecated Please use the postsConnection instead when it is released */
  posts?: Maybe<Array<GroupPost>>;
  postsConnection: GroupPostConnection;
  /** @deprecated Please use groupType instead */
  type?: Maybe<Group_Type>;
};

export type GroupDocumentsConnectionArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GroupPostsConnectionArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GroupFeedConnection = {
  __typename?: "GroupFeedConnection";
  edges: Array<GroupFeedEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]["output"]>;
};

export type GroupFeedEdge = {
  __typename?: "GroupFeedEdge";
  cursor: Scalars["Cursor"]["output"];
  node: GroupPost;
};

export type GroupInput = {
  color?: InputMaybe<Scalars["String"]["input"]>;
  connection?: InputMaybe<Array<Group_Connection>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  externalDescription?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  institution?: InputMaybe<Scalars["ID"]["input"]>;
  logo?: InputMaybe<Scalars["ID"]["input"]>;
  status?: InputMaybe<Scalars["Boolean"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Grouptype>;
};

export type GroupMutation = {
  __typename?: "GroupMutation";
  addMember: DefaultResponse;
  removeMember: DefaultResponse;
  saveGroup: DefaultResponse;
};

export type GroupMutationAddMemberArgs = {
  groupId: Scalars["ID"]["input"];
  permission: Scalars["Int"]["input"];
  profile: ProfileInput;
};

export type GroupMutationRemoveMemberArgs = {
  groupId: Scalars["ID"]["input"];
  profile: ProfileInput;
};

export type GroupMutationSaveGroupArgs = {
  group: GroupInput;
};

export type GroupPost = Node & {
  __typename?: "GroupPost";
  author?: Maybe<Profile>;
  body: Scalars["String"]["output"];
  bumpType: Bump_Type;
  changed: Scalars["DateTime"]["output"];
  comments?: Maybe<Array<Comment>>;
  coverImage?: Maybe<Picture>;
  entity?: Maybe<GroupPostEntity>;
  entityId?: Maybe<Scalars["Int"]["output"]>;
  entityType: Group_Post_Entity_Type;
  group: Group;
  id: Scalars["ID"]["output"];
  isChanged: Scalars["Boolean"]["output"];
  reactions?: Maybe<Array<Reaction>>;
  time: Scalars["DateTime"]["output"];
  videoUrl?: Maybe<Scalars["String"]["output"]>;
};

export type GroupPostConnection = {
  __typename?: "GroupPostConnection";
  edges: Array<GroupPostEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]["output"]>;
};

export type GroupPostEdge = {
  __typename?: "GroupPostEdge";
  cursor: Scalars["Cursor"]["output"];
  node: GroupPost;
};

export type GroupPostEntity =
  | Document
  | Gallery
  | Meal
  | News
  | RepeatingActivity
  | RepeatingMeeting
  | SingleActivity
  | SingleMeeting;

export type GroupQuery = {
  __typename?: "GroupQuery";
  getExternalGroups?: Maybe<Array<Group>>;
  getGroupsByFilters?: Maybe<Array<Group>>;
};

export type GroupQueryGetExternalGroupsArgs = {
  institutionId: Scalars["ID"]["input"];
  type: Group_Connection;
};

export type GroupQueryGetGroupsByFiltersArgs = {
  institutionId: Scalars["ID"]["input"];
  type?: InputMaybe<Array<InputMaybe<Grouptype>>>;
};

export enum Invitation_Status {
  Accepted = "ACCEPTED",
  None = "NONE",
  Pending = "PENDING",
  Rejected = "REJECTED",
}

export type ImageUpload = {
  body?: InputMaybe<Scalars["String"]["input"]>;
  container: Container_Type;
  title?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type InputNotificationSettingActivity = {
  cancelled: Scalars["Boolean"]["input"];
  /** @deprecated Not used */
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** @deprecated Not used */
  reminders?: InputMaybe<Array<InputMaybe<InputReminder>>>;
  timeChanged: Scalars["Boolean"]["input"];
};

export type InputNotificationSettingBooking = {
  deleted: Scalars["Boolean"]["input"];
  /** @deprecated Not used */
  reminders?: InputMaybe<Array<InputMaybe<InputReminder>>>;
  timeChanged: Scalars["Boolean"]["input"];
};

export type InputNotificationSettingGroup = {
  comments: Scalars["Boolean"]["input"];
  join: Scalars["Boolean"]["input"];
  likes: Scalars["Boolean"]["input"];
  post: Scalars["Boolean"]["input"];
};

export type InputNotificationSettingMeeting = {
  cancelled: Scalars["Boolean"]["input"];
  /** @deprecated Not used */
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** @deprecated Not used */
  reminders?: InputMaybe<Array<InputMaybe<InputReminder>>>;
  timeChanged: Scalars["Boolean"]["input"];
};

export type InputNotificationSettingPlanner = {
  assignedToMe: Scalars["Boolean"]["input"];
  assignmentChanged: Scalars["Boolean"]["input"];
  assignmentCompleted: Scalars["Boolean"]["input"];
  assignmentOverdue: Scalars["Boolean"]["input"];
  reminders?: InputMaybe<Array<InputMaybe<InputReminder>>>;
};

export type InputReminder = {
  minutes: Scalars["Int"]["input"];
};

export type InputTextToSpeechFilter = {
  enabled: Scalars["Boolean"]["input"];
  speakingRate: Speaking_Rate;
};

export type Institution = Node & {
  __typename?: "Institution";
  address?: Maybe<Address>;
  color?: Maybe<Scalars["Color"]["output"]>;
  departments?: Maybe<Array<Department>>;
  groups?: Maybe<Array<Group>>;
  id: Scalars["ID"]["output"];
  locale?: Maybe<Scalars["String"]["output"]>;
  logo: Picture;
  name: Scalars["String"]["output"];
  notificationSettings?: Maybe<InstitutionNotificationSettings>;
  phone?: Maybe<Scalars["String"]["output"]>;
  settings: InstitutionSettings;
  /** @deprecated Use settings {showAllDepartments} instead */
  showAllDepartments: Scalars["Boolean"]["output"];
};

export type InstitutionAppSettingMeals = {
  __typename?: "InstitutionAppSettingMeals";
  daysForward: Scalars["Int"]["output"];
  departmentId: Scalars["Int"]["output"];
};

export type InstitutionAppSettingShift = {
  __typename?: "InstitutionAppSettingShift";
  dayDisplay?: Maybe<Scalars["Int"]["output"]>;
  daysForward?: Maybe<Scalars["Int"]["output"]>;
  departmentId?: Maybe<Scalars["Int"]["output"]>;
  gfx?: Maybe<Scalars["Boolean"]["output"]>;
  showCancelledFp?: Maybe<Scalars["Boolean"]["output"]>;
};

export type InstitutionGeneralSettingsFeaturesInput = {
  employeeAdmins?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type InstitutionGeneralSettingsInput = {
  activityAlwaysShowAllUsersInSignupScreen?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  activityHideSignups?: InputMaybe<Scalars["Boolean"]["input"]>;
  autoRenewToken?: InputMaybe<Scalars["Boolean"]["input"]>;
  createMaxResidents?: InputMaybe<Scalars["Int"]["input"]>;
  features?: InputMaybe<InstitutionGeneralSettingsFeaturesInput>;
  galleryPasswordProtected?: InputMaybe<Scalars["Boolean"]["input"]>;
  gdprShowPersonalImageDefault?: InputMaybe<Scalars["Boolean"]["input"]>;
  mitIdLogin?: InputMaybe<Scalars["Boolean"]["input"]>;
  myDay?: InputMaybe<InstitutionGeneralSettingsMyDayInput>;
  screenPasswordProtection?: InputMaybe<InstitutionGeneralSettingsScreenPasswordProtectionInput>;
  videoCall?: InputMaybe<InstitutionGeneralSettingsVideoCallInput>;
  vismaGatWs?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type InstitutionGeneralSettingsMyDayInput = {
  daysForward?: InputMaybe<Scalars["Int"]["input"]>;
  timeTimer?: InputMaybe<InstitutionGeneralSettingsMyDayTimeTimerInput>;
};

export type InstitutionGeneralSettingsMyDayTimeTimerInput = {
  defaultValue?: InputMaybe<Scalars["Boolean"]["input"]>;
  enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type InstitutionGeneralSettingsScreenPasswordProtectionInput = {
  isProtected: Scalars["Boolean"]["input"];
  maxFailed: Scalars["Int"]["input"];
  userLockedOutInMinutes: Scalars["Int"]["input"];
};

export type InstitutionGeneralSettingsVideoCallInput = {
  employee: InstitutionGeneralSettingsVideoCallProfileInput;
  enabled: Scalars["Boolean"]["input"];
  resident: InstitutionGeneralSettingsVideoCallProfileInput;
};

export type InstitutionGeneralSettingsVideoCallProfileInput = {
  enabled: Scalars["Boolean"]["input"];
};

export type InstitutionInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  settings?: InputMaybe<InstitutionSettingsInput>;
};

export type InstitutionMutation = {
  __typename?: "InstitutionMutation";
  save?: Maybe<DefaultResponse>;
  saveEmployeeTitle?: Maybe<DefaultResponse>;
};

export type InstitutionMutationSaveArgs = {
  institution: InstitutionInput;
};

export type InstitutionMutationSaveEmployeeTitleArgs = {
  title: EmployeeTitleInput;
};

export type InstitutionNotificationSettings = {
  __typename?: "InstitutionNotificationSettings";
  activity?: Maybe<NotificationSettings>;
  booking?: Maybe<NotificationSettings>;
  meeting?: Maybe<NotificationSettings>;
};

export type InstitutionPlannerSettingsInput = {
  enabledEmployees?: InputMaybe<Scalars["Boolean"]["input"]>;
  enabledResidents?: InputMaybe<Scalars["Boolean"]["input"]>;
  manager?: InputMaybe<InstitutionSettingPlannerManagerInput>;
  resident?: InputMaybe<ProfilePlannerSettingsInput>;
};

export type InstitutionQuery = {
  __typename?: "InstitutionQuery";
  get?: Maybe<Array<Institution>>;
  getEmployeeTitles?: Maybe<Array<EmployeeTitle>>;
  getMyInstitution: Institution;
};

export type InstitutionQueryGetArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type InstitutionSettingMyDay = {
  __typename?: "InstitutionSettingMyDay";
  daysForward: Scalars["Int"]["output"];
  timeTimer: InstitutionSettingMyDayTimeTimer;
};

export type InstitutionSettingMyDayTimeTimer = {
  __typename?: "InstitutionSettingMyDayTimeTimer";
  defaultValue: Scalars["Boolean"]["output"];
  enabled: Scalars["Boolean"]["output"];
};

export type InstitutionSettingPlanner = {
  __typename?: "InstitutionSettingPlanner";
  enabledEmployees: Scalars["Boolean"]["output"];
  enabledResidents: Scalars["Boolean"]["output"];
  manager?: Maybe<InstitutionSettingPlannerManager>;
  resident?: Maybe<ProfilePlannerAssignmentSettings>;
};

export type InstitutionSettingPlannerManager = {
  __typename?: "InstitutionSettingPlannerManager";
  showOverdue?: Maybe<Scalars["Boolean"]["output"]>;
  /** @deprecated use Institution { settings { myDay { timeTimer } } } */
  timeTimer?: Maybe<Scalars["Boolean"]["output"]>;
};

export type InstitutionSettingPlannerManagerInput = {
  showOverdue?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** @deprecated use InstitutionGeneralSettingsInput { InstitutionGeneralSettingsMyDayInput { timeTimer } } */
  timeTimer?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type InstitutionSettingScreen = {
  __typename?: "InstitutionSettingScreen";
  activityHideSignups: Scalars["Boolean"]["output"];
  galleryPasswordProtected: Scalars["Boolean"]["output"];
  passwordProtection?: Maybe<InstitutionSettingScreenPassword>;
};

export type InstitutionSettingScreenPassword = {
  __typename?: "InstitutionSettingScreenPassword";
  isProtected: Scalars["Boolean"]["output"];
  maxFailed: Scalars["Int"]["output"];
  userLockedOutInMinutes: Scalars["Int"]["output"];
};

export type InstitutionSettings = {
  __typename?: "InstitutionSettings";
  autoRenewToken: Scalars["Boolean"]["output"];
  /** @deprecated Use Department {settings {meal}} instead */
  meal?: Maybe<Array<InstitutionAppSettingMeals>>;
  myDay: InstitutionSettingMyDay;
  planner: InstitutionSettingPlanner;
  screen: InstitutionSettingScreen;
  /** @deprecated Use Department {settings {shift}} instead */
  shift?: Maybe<Array<InstitutionAppSettingShift>>;
  showAllDepartments: Scalars["Boolean"]["output"];
  showResInfoDefault: Scalars["Boolean"]["output"];
  useExternalMeals: Scalars["Boolean"]["output"];
  useExternalMedia: Scalars["Boolean"]["output"];
  vismaGatWs: Scalars["Boolean"]["output"];
};

export type InstitutionSettingsInput = {
  generalSettings?: InputMaybe<InstitutionGeneralSettingsInput>;
  plannerSettings?: InputMaybe<InstitutionPlannerSettingsInput>;
};

export enum Module_List {
  Activities = "ACTIVITIES",
  Booking = "BOOKING",
  ExternalActivities = "EXTERNAL_ACTIVITIES",
  Gallery = "GALLERY",
  JourneyPlanner = "JOURNEY_PLANNER",
  LaterWorkSchedule = "LATER_WORK_SCHEDULE",
  MealPlan = "MEAL_PLAN",
  Meetings = "MEETINGS",
  News = "NEWS",
  OurHome = "OUR_HOME",
  SimpleWorkSchedule = "SIMPLE_WORK_SCHEDULE",
  Slideshow = "SLIDESHOW",
  Weather = "WEATHER",
  WorkSchedule = "WORK_SCHEDULE",
}

export enum Module_Type {
  Activity = "ACTIVITY",
  Booking = "BOOKING",
  Gallery = "GALLERY",
  Groups = "GROUPS",
  Library = "LIBRARY",
  Meal = "MEAL",
  Meeting = "MEETING",
  MyDay = "MY_DAY",
  News = "NEWS",
  Profiles = "PROFILES",
  WhatsHappening = "WHATS_HAPPENING",
  WorkSchedule = "WORK_SCHEDULE",
}

export type Meal = Node & {
  __typename?: "Meal";
  cancelled: Scalars["Boolean"]["output"];
  coverImage?: Maybe<Picture>;
  departments?: Maybe<Array<Department>>;
  description?: Maybe<Scalars["String"]["output"]>;
  dishes?: Maybe<Array<MealDish>>;
  /** @deprecated Doesn't exist */
  documents?: Maybe<Array<Document>>;
  endDate: Scalars["DateTime"]["output"];
  groups?: Maybe<Array<Group>>;
  id: Scalars["ID"]["output"];
  image?: Maybe<Picture>;
  institution: Institution;
  /** @deprecated Doesn't exist */
  location?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Doesn't exist */
  maxSignups?: Maybe<Scalars["Int"]["output"]>;
  participants?: Maybe<Array<Profile>>;
  participating: Scalars["Boolean"]["output"];
  /** @deprecated Use dishes instead */
  parts?: Maybe<Array<MealPart>>;
  /** @deprecated Doesn't exist */
  pictograms?: Maybe<Array<Pictogram>>;
  pictures?: Maybe<Array<Picture>>;
  price?: Maybe<Array<EventPrice>>;
  read: Scalars["Boolean"]["output"];
  signupClose: Scalars["Int"]["output"];
  signupEnabled: Scalars["Boolean"]["output"];
  signupEnd?: Maybe<Scalars["DateTime"]["output"]>;
  signupPossible: Scalars["Boolean"]["output"];
  signupStart?: Maybe<Scalars["DateTime"]["output"]>;
  startDate: Scalars["DateTime"]["output"];
  title: Scalars["String"]["output"];
  /** @deprecated Doesn't exist */
  videos?: Maybe<Array<Video>>;
  withdrawPossible: Scalars["Boolean"]["output"];
};

export type MealCalendar = {
  __typename?: "MealCalendar";
  date: Scalars["Date"]["output"];
  empty?: Maybe<Scalars["Boolean"]["output"]>;
  participating?: Maybe<Scalars["Boolean"]["output"]>;
};

export type MealDish = {
  __typename?: "MealDish";
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  picture?: Maybe<Picture>;
  title: Scalars["String"]["output"];
};

export type MealInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  employeesSignedUp?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  image?: InputMaybe<Scalars["ID"]["input"]>;
  institution?: InputMaybe<Scalars["ID"]["input"]>;
  mealType?: InputMaybe<Scalars["ID"]["input"]>;
  residentsSignedUp?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  signup?: InputMaybe<Scalars["Boolean"]["input"]>;
  signupClose?: InputMaybe<Scalars["Int"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type MealMutation = {
  __typename?: "MealMutation";
  deleteMeal: DefaultResponse;
  saveMeal: DefaultResponse;
};

export type MealMutationDeleteMealArgs = {
  id: Scalars["ID"]["input"];
};

export type MealMutationSaveMealArgs = {
  dishes?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  meal: MealInput;
};

export type MealPart = {
  __typename?: "MealPart";
  description?: Maybe<Scalars["String"]["output"]>;
  picture?: Maybe<Picture>;
  title: Scalars["String"]["output"];
};

export type MealQuery = {
  __typename?: "MealQuery";
  getMeals?: Maybe<Array<Meal>>;
};

export type MealQueryGetMealsArgs = {
  ids: Array<Scalars["ID"]["input"]>;
};

export type MealSubscription = {
  id: Scalars["ID"]["input"];
};

export type Meeting = RepeatingMeeting | SingleMeeting;

export type MeetingAgendaPoint = Node & {
  __typename?: "MeetingAgendaPoint";
  id: Scalars["ID"]["output"];
  picture?: Maybe<Picture>;
  title: Scalars["String"]["output"];
};

export type MeetingCalendar = {
  __typename?: "MeetingCalendar";
  date: Scalars["Date"]["output"];
  empty?: Maybe<Scalars["Boolean"]["output"]>;
  participating?: Maybe<Scalars["Boolean"]["output"]>;
};

export type MeetingInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  institution?: InputMaybe<Scalars["ID"]["input"]>;
  isRepeat?: InputMaybe<Scalars["Boolean"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  meetingType?: InputMaybe<Scalars["ID"]["input"]>;
  repeatId?: InputMaybe<Scalars["Int"]["input"]>;
  signup?: InputMaybe<Scalars["Boolean"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type MeetingMutation = {
  __typename?: "MeetingMutation";
  cancelSignup?: Maybe<DefaultResponse>;
  cancelSignupScreen?: Maybe<DefaultResponse>;
  cancelSuggestion?: Maybe<DefaultResponse>;
  cancelSuggestionScreen?: Maybe<DefaultResponse>;
  delete?: Maybe<DefaultResponse>;
  save?: Maybe<DefaultResponse>;
  saveNotificationSettings?: Maybe<DefaultResponse>;
  signup?: Maybe<DefaultResponse>;
  signupScreen?: Maybe<DefaultResponse>;
  suggest?: Maybe<DefaultResponse>;
  suggestScreen?: Maybe<DefaultResponse>;
};

export type MeetingMutationCancelSignupArgs = {
  subscription: MeetingSubscription;
};

export type MeetingMutationCancelSignupScreenArgs = {
  profile: ScreenProfile;
  subscription: MeetingSubscription;
};

export type MeetingMutationCancelSuggestionArgs = {
  meetingSuggestionId: Scalars["ID"]["input"];
};

export type MeetingMutationCancelSuggestionScreenArgs = {
  meetingSuggestionId: Scalars["ID"]["input"];
  profile: ScreenProfile;
};

export type MeetingMutationDeleteArgs = {
  id: Scalars["ID"]["input"];
};

export type MeetingMutationSaveArgs = {
  meeting: MeetingInput;
};

export type MeetingMutationSaveNotificationSettingsArgs = {
  settings: NotificationSettingsInput;
};

export type MeetingMutationSignupArgs = {
  subscription: MeetingSubscription;
};

export type MeetingMutationSignupScreenArgs = {
  profile: ScreenProfile;
  subscription: MeetingSubscription;
};

export type MeetingMutationSuggestArgs = {
  meetingId: Scalars["ID"]["input"];
  suggestion: Scalars["String"]["input"];
};

export type MeetingMutationSuggestScreenArgs = {
  meetingId: Scalars["ID"]["input"];
  profile: ScreenProfile;
  suggestion: Scalars["String"]["input"];
};

export type MeetingSubscription = {
  id: Scalars["ID"]["input"];
};

export type MeetingSuggestion = Node & {
  __typename?: "MeetingSuggestion";
  id: Scalars["ID"]["output"];
  suggester?: Maybe<Profile>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type ModuleForApp = {
  __typename?: "ModuleForApp";
  id: Scalars["ID"]["output"];
  type?: Maybe<Module_List>;
};

export type ModuleForScreen = {
  __typename?: "ModuleForScreen";
  colSpan?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  rowSpan?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Module_List>;
};

export type ModuleForScreenInput = {
  colSpan: Scalars["String"]["input"];
  rowSpan: Scalars["String"]["input"];
  type?: InputMaybe<Module_List>;
};

export type Mutation = {
  __typename?: "Mutation";
  activity?: Maybe<ActivityMutation>;
  activityCancelSignup?: Maybe<DefaultResponse>;
  activityCancelSignupScreen?: Maybe<DefaultResponse>;
  activitySignup?: Maybe<DefaultResponse>;
  activitySignupScreen?: Maybe<DefaultResponse>;
  app?: Maybe<AppMutation>;
  approvePlannerTask?: Maybe<DefaultResponse>;
  archiveAdd?: Maybe<DefaultResponse>;
  archiveAddScreen?: Maybe<DefaultResponse>;
  archiveRemove?: Maybe<DefaultResponse>;
  archiveRemoveScreen?: Maybe<DefaultResponse>;
  archiveUpload?: Maybe<DefaultResponse>;
  booking?: Maybe<BookingMutation>;
  /** @deprecated Use booking {saveBooking} instead */
  bookingResourceBook?: Maybe<DefaultResponse>;
  bookingResourceBookSlot?: Maybe<DefaultResponse>;
  /** @deprecated Use booking {cancelBooking} instead */
  bookingResourceCancelBooking?: Maybe<DefaultResponse>;
  bookingResourceCancelBookingSlot?: Maybe<DefaultResponse>;
  call?: Maybe<CallMutation>;
  /** @deprecated Use device {register} instead */
  changeDeviceSettings?: Maybe<DefaultResponse>;
  /** @deprecated Use user {changeSettings} and user {saveProfile} instead */
  changeSettings?: Maybe<DefaultResponse>;
  completePlannerSubtask?: Maybe<PlannerTaskActionResponse>;
  completePlannerTask?: Maybe<PlannerTaskActionResponse>;
  contact?: Maybe<ContactMutation>;
  createPlannerTask?: Maybe<PlannerTaskActionResponse>;
  deleteComment?: Maybe<DefaultResponse>;
  deleteDepartment?: Maybe<DeleteDepartmentResponse>;
  deletePlannerTask?: Maybe<DefaultResponse>;
  deletePost?: Maybe<DefaultResponse>;
  deleteUser?: Maybe<DefaultResponse>;
  department?: Maybe<DepartmentMutation>;
  device?: Maybe<DeviceMutation>;
  editComment?: Maybe<DefaultResponse>;
  /** @deprecated Use department { save } instead */
  editDepartment?: Maybe<DefaultResponse>;
  editPlannerTask?: Maybe<PlannerTaskActionResponse>;
  editPost?: Maybe<DefaultResponse>;
  gallery?: Maybe<GalleryMutation>;
  group?: Maybe<GroupMutation>;
  institution?: Maybe<InstitutionMutation>;
  meal?: Maybe<MealMutation>;
  mealCancelSignup?: Maybe<DefaultResponse>;
  mealCancelSignupScreen?: Maybe<DefaultResponse>;
  mealSignup?: Maybe<DefaultResponse>;
  mealSignupScreen?: Maybe<DefaultResponse>;
  meeting?: Maybe<MeetingMutation>;
  /** @deprecated Use meeting {cancelSignup} instead */
  meetingCancelSignup?: Maybe<DefaultResponse>;
  /** @deprecated Use meeting {cancelSuggestion} instead */
  meetingCancelSuggestion?: Maybe<DefaultResponse>;
  /** @deprecated Use meeting {signup} instead */
  meetingSignup?: Maybe<DefaultResponse>;
  /** @deprecated Use meeting {suggest} instead */
  meetingSuggest?: Maybe<DefaultResponse>;
  news?: Maybe<NewsMutation>;
  newsRead?: Maybe<DefaultResponse>;
  notification?: Maybe<NotificationMutation>;
  planner?: Maybe<PlannerMutation>;
  postComment?: Maybe<DefaultResponse>;
  postPost?: Maybe<DefaultResponse>;
  reactComment?: Maybe<DefaultResponse>;
  reactPost?: Maybe<DefaultResponse>;
  rejectPlannerTask?: Maybe<DefaultResponse>;
  resetSetting?: Maybe<DefaultResponse>;
  resource?: Maybe<ResourceMutation>;
  self?: Maybe<SelfMutation>;
  shift?: Maybe<ShiftMutation>;
  uncompletePlannerSubtask?: Maybe<PlannerTaskActionResponse>;
  uncompletePlannerTask?: Maybe<PlannerTaskActionResponse>;
  user?: Maybe<UserMutation>;
};

export type MutationActivityCancelSignupArgs = {
  subscription: ActivitySubscription;
};

export type MutationActivityCancelSignupScreenArgs = {
  profile: ScreenProfile;
  subscription: ActivitySubscription;
};

export type MutationActivitySignupArgs = {
  subscription: ActivitySubscription;
};

export type MutationActivitySignupScreenArgs = {
  profile: ScreenProfile;
  subscription: ActivitySubscription;
};

export type MutationApprovePlannerTaskArgs = {
  id: Scalars["ID"]["input"];
  isRepeat: Scalars["Boolean"]["input"];
};

export type MutationArchiveAddArgs = {
  resourceId: Scalars["ID"]["input"];
};

export type MutationArchiveAddScreenArgs = {
  profile: ScreenProfile;
  resourceId: Scalars["ID"]["input"];
};

export type MutationArchiveRemoveArgs = {
  resourceId: Scalars["ID"]["input"];
};

export type MutationArchiveRemoveScreenArgs = {
  profile: ScreenProfile;
  resourceId: Scalars["ID"]["input"];
};

export type MutationArchiveUploadArgs = {
  image: ImageUpload;
};

export type MutationBookingResourceBookArgs = {
  booking: BookingResourceBooking;
};

export type MutationBookingResourceBookSlotArgs = {
  bookingSlot: BookingResourceBookingSlot;
};

export type MutationBookingResourceCancelBookingArgs = {
  bookingResourceBookingId: Scalars["ID"]["input"];
};

export type MutationBookingResourceCancelBookingSlotArgs = {
  bookingSlot: BookingResourceBookingSlot;
};

export type MutationChangeDeviceSettingsArgs = {
  deviceToken: Scalars["String"]["input"];
  deviceType?: InputMaybe<Scalars["String"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  receiveNotifications: Scalars["Boolean"]["input"];
  version?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationChangeSettingsArgs = {
  email?: InputMaybe<Scalars["Boolean"]["input"]>;
  notificationActivity?: InputMaybe<InputNotificationSettingActivity>;
  notificationBooking?: InputMaybe<InputNotificationSettingBooking>;
  notificationGroup?: InputMaybe<InputNotificationSettingGroup>;
  notificationMeeting?: InputMaybe<InputNotificationSettingMeeting>;
  notificationPlanner?: InputMaybe<InputNotificationSettingPlanner>;
  push?: InputMaybe<Scalars["Boolean"]["input"]>;
  startScreen?: InputMaybe<Start_Screen>;
  textToSpeechFilter?: InputMaybe<InputTextToSpeechFilter>;
};

export type MutationCompletePlannerSubtaskArgs = {
  plannerSubtaskId: Scalars["ID"]["input"];
  plannerTaskId: Scalars["ID"]["input"];
};

export type MutationCompletePlannerTaskArgs = {
  plannerTaskId: Scalars["ID"]["input"];
};

export type MutationCreatePlannerTaskArgs = {
  plannerTask: PlannerTaskCreateInput;
};

export type MutationDeleteCommentArgs = {
  commentId: Scalars["ID"]["input"];
};

export type MutationDeleteDepartmentArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeletePlannerTaskArgs = {
  input: DeletePlannerTaskInput;
};

export type MutationDeletePostArgs = {
  postId: Scalars["ID"]["input"];
};

export type MutationDeleteUserArgs = {
  username: Scalars["String"]["input"];
};

export type MutationEditCommentArgs = {
  body: Scalars["String"]["input"];
  commentId: Scalars["ID"]["input"];
  coverImage?: InputMaybe<ImageUpload>;
};

export type MutationEditDepartmentArgs = {
  department: DepartmentInput;
};

export type MutationEditPlannerTaskArgs = {
  plannerTask: PlannerTaskEditInput;
  plannerTaskId: Scalars["ID"]["input"];
};

export type MutationEditPostArgs = {
  body: Scalars["String"]["input"];
  coverImage?: InputMaybe<ImageUpload>;
  postId: Scalars["ID"]["input"];
};

export type MutationMealCancelSignupArgs = {
  subscription: MealSubscription;
};

export type MutationMealCancelSignupScreenArgs = {
  profile: ScreenProfile;
  subscription: MealSubscription;
};

export type MutationMealSignupArgs = {
  subscription: MealSubscription;
};

export type MutationMealSignupScreenArgs = {
  profile: ScreenProfile;
  subscription: MealSubscription;
};

export type MutationMeetingCancelSignupArgs = {
  subscription: MeetingSubscription;
};

export type MutationMeetingCancelSuggestionArgs = {
  meetingSuggestionId: Scalars["ID"]["input"];
};

export type MutationMeetingSignupArgs = {
  subscription: MeetingSubscription;
};

export type MutationMeetingSuggestArgs = {
  meetingId: Scalars["ID"]["input"];
  suggestion: Scalars["String"]["input"];
};

export type MutationNewsReadArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationPostCommentArgs = {
  body: Scalars["String"]["input"];
  coverImage?: InputMaybe<ImageUpload>;
  postId: Scalars["ID"]["input"];
};

export type MutationPostPostArgs = {
  body: Scalars["String"]["input"];
  coverImage?: InputMaybe<ImageUpload>;
  entity?: InputMaybe<PostEntityLink>;
  groupId: Scalars["ID"]["input"];
};

export type MutationReactCommentArgs = {
  commentId: Scalars["ID"]["input"];
  type?: InputMaybe<Reaction_Type>;
};

export type MutationReactPostArgs = {
  postId: Scalars["ID"]["input"];
  type?: InputMaybe<Reaction_Type>;
};

export type MutationRejectPlannerTaskArgs = {
  id: Scalars["ID"]["input"];
  isRepeat: Scalars["Boolean"]["input"];
};

export type MutationResetSettingArgs = {
  type?: InputMaybe<Reset_Entity_Type>;
};

export type MutationUncompletePlannerSubtaskArgs = {
  plannerSubtaskId: Scalars["ID"]["input"];
  plannerTaskId: Scalars["ID"]["input"];
};

export type MutationUncompletePlannerTaskArgs = {
  plannerTaskId: Scalars["ID"]["input"];
};

export type MyDayCalendar = {
  __typename?: "MyDayCalendar";
  date: Scalars["Date"]["output"];
  empty?: Maybe<Scalars["Boolean"]["output"]>;
};

export enum Notification_Source_Type {
  Group = "GROUP",
  Institution = "INSTITUTION",
}

export enum Notification_Type {
  Activity = "ACTIVITY",
  Booking = "BOOKING",
  Group = "GROUP",
  GroupPost = "GROUP_POST",
  Meeting = "MEETING",
  PlannerAssignment = "PLANNER_ASSIGNMENT",
}

export type News = Node & {
  __typename?: "News";
  body: Scalars["String"]["output"];
  coverImage?: Maybe<Picture>;
  departments?: Maybe<Array<Department>>;
  documents?: Maybe<Array<Document>>;
  endDate: Scalars["DateTime"]["output"];
  groups?: Maybe<Array<Group>>;
  id: Scalars["ID"]["output"];
  image?: Maybe<Picture>;
  institution: Institution;
  pictograms?: Maybe<Array<Pictogram>>;
  pictures?: Maybe<Array<Picture>>;
  published?: Maybe<Scalars["DateTime"]["output"]>;
  read: Scalars["Boolean"]["output"];
  startDate: Scalars["DateTime"]["output"];
  title: Scalars["String"]["output"];
  videos?: Maybe<Array<Video>>;
};

export type NewsInput = {
  departments?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  documents?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  groups?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  image?: InputMaybe<Scalars["ID"]["input"]>;
  institution?: InputMaybe<Scalars["ID"]["input"]>;
  pictograms?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  pictures?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  videos?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type NewsMutation = {
  __typename?: "NewsMutation";
  delete?: Maybe<DefaultResponse>;
  save?: Maybe<DefaultResponse>;
};

export type NewsMutationDeleteArgs = {
  id: Scalars["ID"]["input"];
};

export type NewsMutationSaveArgs = {
  news: NewsInput;
};

export type NewsQuery = {
  __typename?: "NewsQuery";
  getNewsByFilters?: Maybe<Array<News>>;
};

export type NewsQueryGetNewsByFiltersArgs = {
  dateFrom: Scalars["DateTime"]["input"];
  dateTo: Scalars["DateTime"]["input"];
  filter: Filter;
  visibility?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Node = {
  id: Scalars["ID"]["output"];
};

export type Notification = Node & {
  __typename?: "Notification";
  body?: Maybe<Scalars["String"]["output"]>;
  color?: Maybe<Scalars["String"]["output"]>;
  coverImage?: Maybe<Picture>;
  entityId?: Maybe<Scalars["ID"]["output"]>;
  id: Scalars["ID"]["output"];
  profileId?: Maybe<Scalars["ID"]["output"]>;
  profileType?: Maybe<Profile_Type>;
  read: Scalars["Boolean"]["output"];
  source?: Maybe<NotificationSource>;
  time: Scalars["DateTime"]["output"];
  title: Scalars["String"]["output"];
  type: Notification_Type;
};

export type NotificationConnection = {
  __typename?: "NotificationConnection";
  edges: Array<NotificationEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]["output"]>;
};

export type NotificationEdge = {
  __typename?: "NotificationEdge";
  cursor: Scalars["Cursor"]["output"];
  node: Notification;
};

export type NotificationMutation = {
  __typename?: "NotificationMutation";
  markAsRead?: Maybe<DefaultResponse>;
};

export type NotificationMutationMarkAsReadArgs = {
  ids: Array<Scalars["ID"]["input"]>;
};

export type NotificationQuery = {
  __typename?: "NotificationQuery";
  getNotifications: NotificationConnection;
};

export type NotificationQueryGetNotificationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  first: Scalars["Int"]["input"];
};

export type NotificationSetting = {
  __typename?: "NotificationSetting";
  activity: NotificationSettingActivity;
  booking: NotificationSettingBooking;
  email: Scalars["Boolean"]["output"];
  group: NotificationSettingGroup;
  meeting: NotificationSettingMeeting;
  planner: NotificationSettingPlanner;
  push: Scalars["Boolean"]["output"];
};

export type NotificationSettingActivity = {
  __typename?: "NotificationSettingActivity";
  cancelled: Scalars["Boolean"]["output"];
  /** @deprecated deleted trigger is no longer used */
  deleted: Scalars["Boolean"]["output"];
  reminders?: Maybe<Array<Maybe<Reminder>>>;
  timeChanged: Scalars["Boolean"]["output"];
};

export type NotificationSettingBooking = {
  __typename?: "NotificationSettingBooking";
  deleted: Scalars["Boolean"]["output"];
  reminders?: Maybe<Array<Maybe<Reminder>>>;
  timeChanged: Scalars["Boolean"]["output"];
};

export type NotificationSettingGroup = {
  __typename?: "NotificationSettingGroup";
  comments: Scalars["Boolean"]["output"];
  join: Scalars["Boolean"]["output"];
  likes: Scalars["Boolean"]["output"];
  post: Scalars["Boolean"]["output"];
};

export type NotificationSettingMeeting = {
  __typename?: "NotificationSettingMeeting";
  cancelled: Scalars["Boolean"]["output"];
  /** @deprecated deleted trigger is no longer used */
  deleted: Scalars["Boolean"]["output"];
  reminders?: Maybe<Array<Maybe<Reminder>>>;
  timeChanged: Scalars["Boolean"]["output"];
};

export type NotificationSettingPlanner = {
  __typename?: "NotificationSettingPlanner";
  assignedToMe: Scalars["Boolean"]["output"];
  assignmentChanged: Scalars["Boolean"]["output"];
  assignmentCompleted: Scalars["Boolean"]["output"];
  assignmentOverdue: Scalars["Boolean"]["output"];
  reminders?: Maybe<Array<Maybe<Reminder>>>;
};

export type NotificationSettings = {
  __typename?: "NotificationSettings";
  reminderFirst?: Maybe<Scalars["Int"]["output"]>;
  reminderSecond?: Maybe<Scalars["Int"]["output"]>;
  status: Scalars["Boolean"]["output"];
  triggerWhenCancelled: Scalars["Boolean"]["output"];
  triggerWhenTimeChange: Scalars["Boolean"]["output"];
};

export type NotificationSettingsInput = {
  entityId?: InputMaybe<Scalars["ID"]["input"]>;
  institution?: InputMaybe<Scalars["ID"]["input"]>;
  reminderFirst?: InputMaybe<Scalars["Int"]["input"]>;
  reminderSecond?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<Scalars["Boolean"]["input"]>;
  triggerWhenCancelled?: InputMaybe<Scalars["Boolean"]["input"]>;
  triggerWhenTimeChange?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type NotificationSource = {
  __typename?: "NotificationSource";
  color?: Maybe<Scalars["Color"]["output"]>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  type?: Maybe<Notification_Source_Type>;
};

export type NotificationsReadConnection = {
  __typename?: "NotificationsReadConnection";
  edges: Array<NotificationEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]["output"]>;
};

export type NotificationsUnreadCount = {
  __typename?: "NotificationsUnreadCount";
  count: Scalars["Int"]["output"];
};

export enum Pictogram_Type {
  Icon = "ICON",
  Image = "IMAGE",
}

export enum Plannertask_Assignee_Type {
  Department = "DEPARTMENT",
  Employee = "EMPLOYEE",
  Resident = "RESIDENT",
}

export enum Planner_Notification_Type {
  Employee = "EMPLOYEE",
  Resident = "RESIDENT",
}

export enum Planner_Task_Delete_Type {
  OnlyInstance = "ONLY_INSTANCE",
  Series = "SERIES",
}

export enum Profile_Notification_Status {
  Active = "ACTIVE",
  Disabled = "DISABLED",
  Fallback = "FALLBACK",
}

export enum Profile_Type {
  Department = "DEPARTMENT",
  Employee = "EMPLOYEE",
  Guest = "GUEST",
  Relative = "RELATIVE",
  Resident = "RESIDENT",
}

export type PageInfo = {
  __typename?: "PageInfo";
  endCursor?: Maybe<Scalars["Cursor"]["output"]>;
  hasNextPage: Scalars["Boolean"]["output"];
  hasPreviousPage: Scalars["Boolean"]["output"];
  startCursor?: Maybe<Scalars["Cursor"]["output"]>;
};

export type PcdMeal = {
  __typename?: "PcdMeal";
  courses: Array<PcdMealCourse>;
  date: Scalars["Date"]["output"];
};

export type PcdMealCourse = {
  __typename?: "PcdMealCourse";
  category: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  title: Scalars["String"]["output"];
};

export type Pictogram = Node & {
  __typename?: "Pictogram";
  blurhash?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  source: Scalars["String"]["output"];
  type?: Maybe<Pictogram_Type>;
};

export type Picture = Node & {
  __typename?: "Picture";
  blurhash?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  inMyArchive?: Maybe<Scalars["Boolean"]["output"]>;
  source: Scalars["String"]["output"];
};

export type PlannerCategory = Node & {
  __typename?: "PlannerCategory";
  color: Scalars["Color"]["output"];
  id: Scalars["ID"]["output"];
  title: Scalars["String"]["output"];
};

export type PlannerCategoryInput = {
  color?: InputMaybe<Scalars["Color"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  institution?: InputMaybe<Scalars["ID"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type PlannerFilter = {
  __typename?: "PlannerFilter";
  assigneeTypes?: Maybe<Array<Plannertask_Assignee_Type>>;
  categories?: Maybe<Array<Scalars["ID"]["output"]>>;
  departments?: Maybe<Array<Scalars["ID"]["output"]>>;
  employees?: Maybe<Array<Scalars["ID"]["output"]>>;
  id?: Maybe<Scalars["ID"]["output"]>;
  residents?: Maybe<Array<Scalars["ID"]["output"]>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type PlannerFilterInput = {
  assigneeTypes?: InputMaybe<Array<Plannertask_Assignee_Type>>;
  categories?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  departments?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  employees?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  institution?: InputMaybe<Scalars["ID"]["input"]>;
  residents?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type PlannerMutation = {
  __typename?: "PlannerMutation";
  saveCategory?: Maybe<DefaultResponse>;
  saveFilter?: Maybe<DefaultResponse>;
  saveTemplate?: Maybe<DefaultResponse>;
};

export type PlannerMutationSaveCategoryArgs = {
  category: PlannerCategoryInput;
};

export type PlannerMutationSaveFilterArgs = {
  filter: PlannerFilterInput;
};

export type PlannerMutationSaveTemplateArgs = {
  template: PlannerTemplateInput;
};

export type PlannerNotificationSettingNotifications = {
  plannerFirst?: InputMaybe<Scalars["Int"]["input"]>;
  plannerFourth?: InputMaybe<Scalars["Int"]["input"]>;
  plannerSecond?: InputMaybe<Scalars["Int"]["input"]>;
  plannerThird?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PlannerNotificationSettingTriggers = {
  change: Scalars["Boolean"]["input"];
  complete: Scalars["Boolean"]["input"];
  create: Scalars["Boolean"]["input"];
  overdue: Scalars["Boolean"]["input"];
};

export type PlannerNotificationSettingsInput = {
  notificationType: Planner_Notification_Type;
  notifications: PlannerNotificationSettingNotifications;
  state: Scalars["String"]["input"];
  triggers: PlannerNotificationSettingTriggers;
};

export type PlannerQuery = {
  __typename?: "PlannerQuery";
  getCategories?: Maybe<Array<PlannerCategory>>;
  getFilters?: Maybe<Array<PlannerFilter>>;
  getTasksByFilters?: Maybe<Array<PlannerTask>>;
};

export type PlannerQueryGetTasksByFiltersArgs = {
  dateFrom: Scalars["DateTime"]["input"];
  dateTo: Scalars["DateTime"]["input"];
};

export type PlannerSubtask = Node & {
  __typename?: "PlannerSubtask";
  completed: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  pictogram?: Maybe<Pictogram>;
  picture?: Maybe<Picture>;
  sortOrder?: Maybe<Scalars["Int"]["output"]>;
  title: Scalars["String"]["output"];
};

export type PlannerSubtaskInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  image?: InputMaybe<ImageUpload>;
  pictogramId?: InputMaybe<Scalars["ID"]["input"]>;
  sortOrder?: InputMaybe<Scalars["Int"]["input"]>;
  template?: InputMaybe<Scalars["ID"]["input"]>;
  title: Scalars["String"]["input"];
};

export type PlannerTask = RepeatingPlannerTask | SinglePlannerTask;

export type PlannerTaskActionResponse = {
  __typename?: "PlannerTaskActionResponse";
  entity?: Maybe<PlannerTask>;
  id?: Maybe<Scalars["ID"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type PlannerTaskAssignee = Department | Employee | Resident;

export type PlannerTaskAssigneeInput = {
  id: Scalars["ID"]["input"];
  type: Plannertask_Assignee_Type;
};

export type PlannerTaskCreateInput = {
  approvalState?: InputMaybe<Approval_State>;
  assignees?: InputMaybe<Array<PlannerTaskAssigneeInput>>;
  categoryId?: InputMaybe<Scalars["ID"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  end: Scalars["DateTime"]["input"];
  image?: InputMaybe<ImageUpload>;
  isRepeat?: InputMaybe<Scalars["Boolean"]["input"]>;
  notificationSettings?: InputMaybe<Array<PlannerNotificationSettingsInput>>;
  pictogramId?: InputMaybe<Scalars["ID"]["input"]>;
  /** @deprecated Use approvalState instead */
  private?: InputMaybe<Scalars["Boolean"]["input"]>;
  rrule?: InputMaybe<Scalars["String"]["input"]>;
  start: Scalars["DateTime"]["input"];
  subtasks?: InputMaybe<Array<PlannerSubtaskInput>>;
  title: Scalars["String"]["input"];
  visibleForResident?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PlannerTaskEditInput = {
  approvalState?: InputMaybe<Approval_State>;
  assignees?: InputMaybe<Array<PlannerTaskAssigneeInput>>;
  categoryId?: InputMaybe<Scalars["ID"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  end?: InputMaybe<Scalars["DateTime"]["input"]>;
  image?: InputMaybe<ImageUpload>;
  isRepeat?: InputMaybe<Scalars["Boolean"]["input"]>;
  notificationSettings?: InputMaybe<Array<PlannerNotificationSettingsInput>>;
  pictogramId?: InputMaybe<Scalars["ID"]["input"]>;
  /** @deprecated Use approvalState instead */
  private?: InputMaybe<Scalars["Boolean"]["input"]>;
  rrule?: InputMaybe<Scalars["String"]["input"]>;
  start?: InputMaybe<Scalars["DateTime"]["input"]>;
  subtasks?: InputMaybe<Array<PlannerSubtaskInput>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  visibleForResident?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PlannerTemplate = {
  __typename?: "PlannerTemplate";
  category?: Maybe<PlannerCategory>;
  /** @deprecated Doesnt exist */
  description?: Maybe<Scalars["String"]["output"]>;
  /** Duration in minutes. Add these to the start time to get end time */
  duration?: Maybe<Scalars["Int"]["output"]>;
  image?: Maybe<Picture>;
  pictogram?: Maybe<Pictogram>;
  private: Scalars["Boolean"]["output"];
  repeat: RepeatRule;
  /** Start time without date. Date should always be current date when applying template */
  start?: Maybe<Scalars["Time"]["output"]>;
  subtasks?: Maybe<Array<PlannerTemplateSubtask>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type PlannerTemplateInput = {
  category?: InputMaybe<Scalars["ID"]["input"]>;
  duration?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  image?: InputMaybe<Scalars["ID"]["input"]>;
  institution?: InputMaybe<Scalars["ID"]["input"]>;
  pictogram?: InputMaybe<Scalars["ID"]["input"]>;
  rrule?: InputMaybe<Scalars["String"]["input"]>;
  start?: InputMaybe<Scalars["Time"]["input"]>;
  subtasks?: InputMaybe<Array<PlannerSubtaskInput>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type PlannerTemplateSubtask = {
  __typename?: "PlannerTemplateSubtask";
  pictogram?: Maybe<Pictogram>;
  picture?: Maybe<Picture>;
  title: Scalars["String"]["output"];
};

export type PostEntityLink = {
  entityId: Scalars["ID"]["input"];
  entityType: Entity_Type;
};

export type Profile = Employee | Relative | Resident;

export type ProfileAppSettingsInput = {
  tts?: InputMaybe<InputTextToSpeechFilter>;
};

export type ProfileGroupPermission = {
  __typename?: "ProfileGroupPermission";
  allowComment: Scalars["Boolean"]["output"];
  allowLike: Scalars["Boolean"]["output"];
  allowPost: Scalars["Boolean"]["output"];
  groupId: Scalars["String"]["output"];
};

export type ProfileGroupSettings = {
  __typename?: "ProfileGroupSettings";
  permissions?: Maybe<Array<ProfileGroupPermission>>;
};

export type ProfileInput = {
  id: Scalars["ID"]["input"];
  type: Profile_Type;
};

export type ProfileMyDaySettings = {
  __typename?: "ProfileMyDaySettings";
  daysForward?: Maybe<Scalars["Int"]["output"]>;
  timeTimer?: Maybe<Scalars["Boolean"]["output"]>;
};

export type ProfileMyDaySettingsInput = {
  daysForward?: InputMaybe<Scalars["Int"]["input"]>;
  timeTimer?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ProfileNotificationSettings = {
  __typename?: "ProfileNotificationSettings";
  activity?: Maybe<ProfileNotificationSettingsEntity>;
  booking?: Maybe<ProfileNotificationSettingsEntity>;
  general?: Maybe<ProfileNotificationSettingsGeneral>;
  group?: Maybe<ProfileNotificationSettingsGroup>;
  meeting?: Maybe<ProfileNotificationSettingsEntity>;
  planner?: Maybe<ProfileNotificationSettingsPlanner>;
};

export type ProfileNotificationSettingsEntity = {
  __typename?: "ProfileNotificationSettingsEntity";
  triggerWhenCancelled: Scalars["Boolean"]["output"];
  triggerWhenTimeChange: Scalars["Boolean"]["output"];
};

export type ProfileNotificationSettingsEntityInput = {
  triggerWhenCancelled?: InputMaybe<Scalars["Boolean"]["input"]>;
  triggerWhenTimeChange?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ProfileNotificationSettingsGeneral = {
  __typename?: "ProfileNotificationSettingsGeneral";
  allowEdit: Scalars["Boolean"]["output"];
  email: Scalars["Boolean"]["output"];
  push: Scalars["Boolean"]["output"];
  status: Profile_Notification_Status;
};

export type ProfileNotificationSettingsGeneralInput = {
  allowEdit?: InputMaybe<Scalars["Boolean"]["input"]>;
  email?: InputMaybe<Scalars["Boolean"]["input"]>;
  push?: InputMaybe<Scalars["Boolean"]["input"]>;
  status?: InputMaybe<Profile_Notification_Status>;
};

export type ProfileNotificationSettingsGroup = {
  __typename?: "ProfileNotificationSettingsGroup";
  triggerWhenBecomeMember: Scalars["Boolean"]["output"];
  triggerWhenCommentsOnOwnPost: Scalars["Boolean"]["output"];
  triggerWhenLikesOnOwnPostsAndComments: Scalars["Boolean"]["output"];
  triggerWhenOtherMemberPosts: Scalars["Boolean"]["output"];
};

export type ProfileNotificationSettingsGroupInput = {
  triggerWhenBecomeMember?: InputMaybe<Scalars["Boolean"]["input"]>;
  triggerWhenCommentsOnOwnPost?: InputMaybe<Scalars["Boolean"]["input"]>;
  triggerWhenLikesOnOwnPostsAndComments?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  triggerWhenOtherMemberPosts?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ProfileNotificationSettingsInput = {
  activity?: InputMaybe<ProfileNotificationSettingsEntityInput>;
  booking?: InputMaybe<ProfileNotificationSettingsEntityInput>;
  general?: InputMaybe<ProfileNotificationSettingsGeneralInput>;
  group?: InputMaybe<ProfileNotificationSettingsGroupInput>;
  meeting?: InputMaybe<ProfileNotificationSettingsEntityInput>;
  planner?: InputMaybe<ProfileNotificationSettingsPlannerInput>;
};

export type ProfileNotificationSettingsPlanner = {
  __typename?: "ProfileNotificationSettingsPlanner";
  reminderFirst?: Maybe<Scalars["Int"]["output"]>;
  reminderFourth?: Maybe<Scalars["Int"]["output"]>;
  reminderSecond?: Maybe<Scalars["Int"]["output"]>;
  reminderThird?: Maybe<Scalars["Int"]["output"]>;
  triggerWhenAssignmentChange: Scalars["Boolean"]["output"];
  triggerWhenAssignmentComplete: Scalars["Boolean"]["output"];
  triggerWhenAssignmentOverdue: Scalars["Boolean"]["output"];
  triggerWhenNewAssignment: Scalars["Boolean"]["output"];
};

export type ProfileNotificationSettingsPlannerInput = {
  reminderFirst?: InputMaybe<Scalars["Int"]["input"]>;
  reminderFourth?: InputMaybe<Scalars["Int"]["input"]>;
  reminderSecond?: InputMaybe<Scalars["Int"]["input"]>;
  reminderThird?: InputMaybe<Scalars["Int"]["input"]>;
  triggerWhenAssignmentChange?: InputMaybe<Scalars["Boolean"]["input"]>;
  triggerWhenAssignmentComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  triggerWhenAssignmentOverdue?: InputMaybe<Scalars["Boolean"]["input"]>;
  triggerWhenNewAssignment?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ProfilePlannerAssignmentSettings = {
  __typename?: "ProfilePlannerAssignmentSettings";
  create: Scalars["Boolean"]["output"];
  edit: Scalars["Boolean"]["output"];
  inbox: Scalars["Boolean"]["output"];
  showAfterCompleted: Scalars["Boolean"]["output"];
  showDescription: Scalars["Boolean"]["output"];
  showOverdue: Scalars["Boolean"]["output"];
  showTime: Scalars["Boolean"]["output"];
  tts: Scalars["Boolean"]["output"];
};

export type ProfilePlannerAssignmentSettingsInput = {
  create?: InputMaybe<Scalars["Boolean"]["input"]>;
  edit?: InputMaybe<Scalars["Boolean"]["input"]>;
  inbox?: InputMaybe<Scalars["Boolean"]["input"]>;
  showAfterCompleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  showDescription?: InputMaybe<Scalars["Boolean"]["input"]>;
  showOverdue?: InputMaybe<Scalars["Boolean"]["input"]>;
  showTime?: InputMaybe<Scalars["Boolean"]["input"]>;
  tts?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ProfilePlannerSettings = {
  __typename?: "ProfilePlannerSettings";
  assignment?: Maybe<ProfilePlannerAssignmentSettings>;
};

export type ProfilePlannerSettingsInput = {
  assignment?: InputMaybe<ProfilePlannerAssignmentSettingsInput>;
};

export type ProfileSettings = {
  __typename?: "ProfileSettings";
  groups?: Maybe<ProfileGroupSettings>;
  myDay?: Maybe<ProfileMyDaySettings>;
  notifications?: Maybe<ProfileNotificationSettings>;
  planner?: Maybe<ProfilePlannerSettings>;
  videoCall?: Maybe<ProfileVideoCallSettings>;
  vismaGatId?: Maybe<Scalars["ID"]["output"]>;
};

export type ProfileSettingsInput = {
  app?: InputMaybe<ProfileAppSettingsInput>;
  myDay?: InputMaybe<ProfileMyDaySettingsInput>;
  notifications?: InputMaybe<ProfileNotificationSettingsInput>;
  planner?: InputMaybe<ProfilePlannerSettingsInput>;
  videoCall?: InputMaybe<ProfileVideoCallSettingsInput>;
  vismaGatId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ProfileStats = {
  __typename?: "ProfileStats";
  numActivitiesParticipating?: Maybe<Scalars["Int"]["output"]>;
  numActivitiesResponsible?: Maybe<Scalars["Int"]["output"]>;
  numBookings?: Maybe<Scalars["Int"]["output"]>;
  numMeals?: Maybe<Scalars["Int"]["output"]>;
  numWorkSchedules?: Maybe<Scalars["Int"]["output"]>;
};

export type ProfileVideoCallSettings = {
  __typename?: "ProfileVideoCallSettings";
  allowCallAll: Scalars["Boolean"]["output"];
  enabled: Scalars["Boolean"]["output"];
  status: Video_Call_Status;
};

export type ProfileVideoCallSettingsInput = {
  allowCallAll?: InputMaybe<Scalars["Boolean"]["input"]>;
  enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  status?: InputMaybe<Video_Call_Status>;
};

export type Query = {
  __typename?: "Query";
  /** @deprecated Use activity {getActivitiesByFilters} instead */
  activities?: Maybe<Array<Activity>>;
  activity?: Maybe<ActivityQuery>;
  activityCalendar?: Maybe<Array<ActivityCalendar>>;
  /** @deprecated Use activity {getActivities} instead */
  activityEntry?: Maybe<Activity>;
  /** @deprecated Use activity {getActivitiesByFilters} instead */
  activityRange?: Maybe<Array<Activity>>;
  appConnectionsScreen?: Maybe<Array<App>>;
  auditLog?: Maybe<AuditLogQuery>;
  bookingResourceBookingsRange?: Maybe<Array<BookingSlot>>;
  bookingResourceCalendar?: Maybe<Array<BookingResourceCalendar>>;
  bookingResourceEntry?: Maybe<BookingResource>;
  bookingResources?: Maybe<Array<BookingResource>>;
  bookingSlotEntry?: Maybe<BookingSlot>;
  bookingSlots?: Maybe<Array<BookingSlot>>;
  /** @deprecated Use user.getContacts instead */
  contacts?: Maybe<Array<Maybe<User>>>;
  department?: Maybe<Department>;
  departmentWeather: Scalars["String"]["output"];
  departments?: Maybe<DepartmentQuery>;
  externalServices?: Maybe<ExternalServicesQuery>;
  galleries?: Maybe<Array<Gallery>>;
  galleriesExport: Scalars["String"]["output"];
  galleryEntry: Gallery;
  group?: Maybe<GroupQuery>;
  groupEntry?: Maybe<Group>;
  groupPostEntry?: Maybe<GroupPost>;
  /** @deprecated Use institutions.getMyInstitution instead */
  institution: Institution;
  institutions?: Maybe<InstitutionQuery>;
  meal?: Maybe<MealQuery>;
  mealCalendar?: Maybe<Array<MealCalendar>>;
  mealEntry?: Maybe<Meal>;
  mealRange?: Maybe<Array<Meal>>;
  meals?: Maybe<Array<Meal>>;
  meetingCalendar?: Maybe<Array<MeetingCalendar>>;
  meetingEntry?: Maybe<Meeting>;
  meetingRange?: Maybe<Array<Meeting>>;
  meetings?: Maybe<Array<Meeting>>;
  myArchive: Archive;
  myArchiveScreen: Archive;
  myBookings?: Maybe<Array<BookingSlot>>;
  myDay?: Maybe<Array<EventEntity>>;
  myDayCalendar: Array<MyDayCalendar>;
  /** @deprecated Use self {groups} instead */
  myGroups?: Maybe<Array<Group>>;
  myGroupsFeed: GroupFeedConnection;
  myProfiles?: Maybe<Array<Profile>>;
  news?: Maybe<Array<News>>;
  newsEntry?: Maybe<News>;
  newsItem?: Maybe<NewsQuery>;
  node?: Maybe<Node>;
  notification?: Maybe<NotificationQuery>;
  notificationEntry?: Maybe<Notification>;
  /** @deprecated Use notification {getNotifications} instead */
  notificationsRead: NotificationsReadConnection;
  /** @deprecated Use notification {getNotifications} instead */
  notificationsUnread: Array<Notification>;
  notificationsUnreadCount: NotificationsUnreadCount;
  pcdMeals?: Maybe<Array<PcdMeal>>;
  pictograms?: Maybe<Array<Pictogram>>;
  planner?: Maybe<PlannerQuery>;
  /** @deprecated use planner {getCategories instead} */
  plannerCategories?: Maybe<Array<PlannerCategory>>;
  plannerTaskEntry?: Maybe<PlannerTask>;
  plannerTasksAwaitingApproval: Array<PlannerTask>;
  plannerTemplates?: Maybe<Array<PlannerTemplate>>;
  profileEntry?: Maybe<Profile>;
  profiles?: Maybe<Array<Profile>>;
  receiveNotificationsDevice?: Maybe<ReceiveNotificationsDevice>;
  schedule?: Maybe<ScheduleQuery>;
  scheduleAvailableEmployees?: Maybe<Array<ScheduleAvailableEmployee>>;
  scheduleCalendar?: Maybe<Array<ScheduleCalendar>>;
  /** @deprecated Use schedule {shifts} instead */
  scheduleShifts?: Maybe<Array<ScheduleShift>>;
  self?: Maybe<SelfQuery>;
  settings?: Maybe<Settings>;
  simpleScheduleShifts?: Maybe<Array<SimpleSchedule>>;
  tts: TextToSpeech;
  /** @deprecated No longer used */
  ttsEntity: TextToSpeech;
  user?: Maybe<UserQuery>;
  whatsHappening?: Maybe<Array<EventEntity>>;
  whatsHappeningCalendar: Array<WhatsHappeningCalendar>;
};

export type QueryActivitiesArgs = {
  date: Scalars["Date"]["input"];
  filter: Filter;
  timezone?: Scalars["TimeZone"]["input"];
};

export type QueryActivityCalendarArgs = {
  dateFrom: Scalars["Date"]["input"];
  dateTo: Scalars["Date"]["input"];
  filter: Filter;
  timezone?: Scalars["TimeZone"]["input"];
};

export type QueryActivityEntryArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryActivityRangeArgs = {
  dateFrom?: InputMaybe<Scalars["Date"]["input"]>;
  dateTo?: InputMaybe<Scalars["Date"]["input"]>;
  filter: Filter;
  timezone?: Scalars["TimeZone"]["input"];
};

export type QueryBookingResourceBookingsRangeArgs = {
  bookingResourceId: Scalars["ID"]["input"];
  from: Scalars["DateTime"]["input"];
  to: Scalars["DateTime"]["input"];
};

export type QueryBookingResourceCalendarArgs = {
  bookFrom?: InputMaybe<Scalars["DateTime"]["input"]>;
  bookTo?: InputMaybe<Scalars["DateTime"]["input"]>;
  bookingResourceId: Scalars["ID"]["input"];
  dateFrom: Scalars["Date"]["input"];
  dateTo: Scalars["Date"]["input"];
  timezone?: Scalars["TimeZone"]["input"];
};

export type QueryBookingResourceEntryArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryBookingResourcesArgs = {
  filter: Filter;
  timezone?: Scalars["TimeZone"]["input"];
};

export type QueryBookingSlotEntryArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryBookingSlotsArgs = {
  bookingResourceId: Scalars["ID"]["input"];
  dateFrom: Scalars["Date"]["input"];
  dateTo: Scalars["Date"]["input"];
  timezone?: Scalars["TimeZone"]["input"];
};

export type QueryDepartmentArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryDepartmentWeatherArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGalleriesArgs = {
  filter: Filter;
};

export type QueryGalleriesExportArgs = {
  ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type QueryGalleryEntryArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGroupEntryArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGroupPostEntryArgs = {
  groupPostId: Scalars["ID"]["input"];
};

export type QueryMealCalendarArgs = {
  dateFrom: Scalars["Date"]["input"];
  dateTo: Scalars["Date"]["input"];
  filter: Filter;
  timezone?: Scalars["TimeZone"]["input"];
};

export type QueryMealEntryArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryMealRangeArgs = {
  dateFrom: Scalars["Date"]["input"];
  dateTo: Scalars["Date"]["input"];
  filter: Filter;
  timezone?: Scalars["TimeZone"]["input"];
};

export type QueryMealsArgs = {
  date: Scalars["Date"]["input"];
  filter: Filter;
  timezone?: Scalars["TimeZone"]["input"];
};

export type QueryMeetingCalendarArgs = {
  dateFrom: Scalars["Date"]["input"];
  dateTo: Scalars["Date"]["input"];
  filter: Filter;
  timezone?: Scalars["TimeZone"]["input"];
};

export type QueryMeetingEntryArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryMeetingRangeArgs = {
  dateFrom: Scalars["Date"]["input"];
  dateTo: Scalars["Date"]["input"];
  filter: Filter;
  timezone?: Scalars["TimeZone"]["input"];
};

export type QueryMeetingsArgs = {
  date: Scalars["Date"]["input"];
  filter: Filter;
  timezone?: Scalars["TimeZone"]["input"];
};

export type QueryMyArchiveScreenArgs = {
  profile: ScreenProfile;
};

export type QueryMyBookingsArgs = {
  dateFrom: Scalars["Date"]["input"];
  dateTo: Scalars["Date"]["input"];
  timezone?: Scalars["TimeZone"]["input"];
};

export type QueryMyDayArgs = {
  dateFrom: Scalars["Date"]["input"];
  dateTo: Scalars["Date"]["input"];
  filter: Filter;
  timezone?: Scalars["TimeZone"]["input"];
};

export type QueryMyDayCalendarArgs = {
  dateFrom: Scalars["Date"]["input"];
  dateTo: Scalars["Date"]["input"];
  filter: Filter;
  timezone?: Scalars["TimeZone"]["input"];
};

export type QueryMyGroupsFeedArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  first: Scalars["Int"]["input"];
};

export type QueryNewsArgs = {
  filter: Filter;
};

export type QueryNewsEntryArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryNodeArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryNotificationEntryArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryNotificationsReadArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  first: Scalars["Int"]["input"];
};

export type QueryPcdMealsArgs = {
  date?: InputMaybe<Scalars["Date"]["input"]>;
};

export type QueryPlannerTaskEntryArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryProfileEntryArgs = {
  profileId: Scalars["ID"]["input"];
  profileType: Profile_Type;
};

export type QueryProfilesArgs = {
  filter: Filter;
};

export type QueryReceiveNotificationsDeviceArgs = {
  deviceToken: Scalars["String"]["input"];
};

export type QueryScheduleAvailableEmployeesArgs = {
  filter: Filter;
  from: Scalars["DateTime"]["input"];
  to: Scalars["DateTime"]["input"];
};

export type QueryScheduleCalendarArgs = {
  dateFrom: Scalars["Date"]["input"];
  dateTo: Scalars["Date"]["input"];
  filter: Filter;
  timezone?: Scalars["TimeZone"]["input"];
};

export type QueryScheduleShiftsArgs = {
  date: Scalars["Date"]["input"];
  filter: Filter;
  timezone?: Scalars["TimeZone"]["input"];
};

export type QuerySimpleScheduleShiftsArgs = {
  date: Scalars["Date"]["input"];
  filter: Filter;
  timezone?: Scalars["TimeZone"]["input"];
};

export type QueryTtsArgs = {
  speakingRate?: InputMaybe<Speaking_Rate>;
  text: Scalars["String"]["input"];
};

export type QueryTtsEntityArgs = {
  entityId: Scalars["ID"]["input"];
  entityType: Tts_Entity_Type;
  speakingRate?: InputMaybe<Speaking_Rate>;
};

export type QueryWhatsHappeningArgs = {
  dateFrom: Scalars["Date"]["input"];
  dateTo: Scalars["Date"]["input"];
  filter: Filter;
  timezone?: Scalars["TimeZone"]["input"];
};

export type QueryWhatsHappeningCalendarArgs = {
  dateFrom: Scalars["Date"]["input"];
  dateTo: Scalars["Date"]["input"];
  filter: Filter;
  timezone?: Scalars["TimeZone"]["input"];
};

export enum Reaction_Type {
  Happy = "HAPPY",
  Heart = "HEART",
  Laugh = "LAUGH",
  Like = "LIKE",
  Sad = "SAD",
}

export enum Reset_Entity_Type {
  Activity = "ACTIVITY",
  Booking = "BOOKING",
  Meeting = "MEETING",
  Planner = "PLANNER",
}

export enum Rrule_Day {
  Fr = "FR",
  Mo = "MO",
  Sa = "SA",
  Su = "SU",
  Th = "TH",
  Tu = "TU",
  We = "WE",
}

export enum Rrule_Frequency {
  Daily = "DAILY",
  Hourly = "HOURLY",
  Minutely = "MINUTELY",
  Monthly = "MONTHLY",
  Secondly = "SECONDLY",
  Weekly = "WEEKLY",
  Yearly = "YEARLY",
}

export enum Rrule_Month {
  April = "APRIL",
  August = "AUGUST",
  December = "DECEMBER",
  February = "FEBRUARY",
  January = "JANUARY",
  July = "JULY",
  June = "JUNE",
  March = "MARCH",
  May = "MAY",
  November = "NOVEMBER",
  October = "OCTOBER",
  September = "SEPTEMBER",
}

export type RRuleByDayInput = {
  /** The day(s) of the week to apply the recurrence to from the following: MO, TU, WE, TH, FR, SA, SU. */
  day: Rrule_Day;
  /** For example: 1MO (the first Monday of the interval), 3MO (the third Monday), -1MO (the last Monday), and so on. */
  occurence?: InputMaybe<Scalars["Int"]["input"]>;
};

/** https://github.com/rlanvin/php-rrule/wiki/RRule */
export type RRuleInput = {
  /** The day(s) of the week to apply the recurrence to */
  BYDAY?: InputMaybe<Array<RRuleByDayInput>>;
  /**  The hour(s) to apply the recurrence to, from 0 to 23. */
  BYHOUR?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  /** The minute(s) to apply the recurrence to, from 0 to 59.  */
  BYMINUTE?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  /** The month(s) to apply the recurrence to, from 1 (January) to 12 (December). */
  BYMONTH?: InputMaybe<Array<Rrule_Month>>;
  /** The day(s) of the month to apply the recurrence to, from 1 to 31 or -31 to -1. Negative values mean that the count starts from the end of the month, so -1 means "the last day the month".  */
  BYMONTHDAY?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  /** The second(s) to apply the recurrence to, from 0 to 60. Warning: leap second (i.e. second 60) support is not fully tested. */
  BYSECOND?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  /** The Nth occurrence(s) within the valid occurrences inside a frequency period.  Negative values mean that the count starts from the set. For example, a bysetpos of -1 if combined with a MONTHLY frequency, and a byweekday of 'MO,TU,WE,TH FR', will result in the last work day of every month. */
  BYSETPOS?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  /**
   * The week number(s) to apply the recurrence to, from 1 to 53 or -53 to -1.
   * Negative values mean that the counting start from the end of the year, so -1 means "the last week of the year".
   * Week numbers have the meaning described in ISO8601, that is, the first week of the year is that containing at least four days of the new year.
   * Week numbers are affected by the WKST setting.
   * Warning: negative week numbers are not fully tested yet.
   */
  BYWEEKNO?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  /** The day(s) of the year to apply the recurrence to, from 1 to 366 or -366 to -1. Negative values mean that the count starts from the end of the year, so -1 means "the last day of the year". */
  BYYEARDAY?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  /** How many occurrences will be generated. */
  COUNT?: InputMaybe<Scalars["Int"]["input"]>;
  /** The recurrence start date and time. Unlike documented in the RFC, this is not necessarily the first recurrence instance, unless it does fit in the specified rule. */
  DTSTART?: InputMaybe<Scalars["DateTime"]["input"]>;
  FREQUENCY?: InputMaybe<Rrule_Frequency>;
  /** The interval between each FREQUENCY iteration. For example, when using YEARLY, an interval of 2 means once every two years, but with HOURLY, it means once every two hours. Default is 1. */
  INTERVAL?: InputMaybe<Scalars["Int"]["input"]>;
  /** The limit of the recurrence. Accepts the same formats as DTSTART. If a recurrence instance happens to be the same the date given, this will be the last occurrence. */
  UNTIL?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** The week start day. Must be one of the following strings MO, TU, WE, TH, FR, SA, SU. This will affect recurrences based on weekly periods. Default is MO (Monday). */
  WKST?: InputMaybe<Rrule_Day>;
};

export type RRuleSetInput = {
  excludedDates: Array<Scalars["DateTime"]["input"]>;
  excludedRules?: InputMaybe<Array<RRuleInput>>;
  rules: Array<RRuleInput>;
};

export type Reaction = {
  __typename?: "Reaction";
  author?: Maybe<Profile>;
  type: Reaction_Type;
};

export type ReceiveNotificationsDevice = {
  __typename?: "ReceiveNotificationsDevice";
  receiveNotifications: Scalars["Boolean"]["output"];
};

export type Relative = {
  __typename?: "Relative";
  alias?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use image instead */
  displayImage: Scalars["String"]["output"];
  displayName: Scalars["String"]["output"];
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  hasAccess: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  image?: Maybe<Picture>;
  institution: Institution;
  lastName?: Maybe<Scalars["String"]["output"]>;
  mobile?: Maybe<Scalars["String"]["output"]>;
  residents?: Maybe<Array<Maybe<Resident>>>;
  settings?: Maybe<ProfileSettings>;
  showImage?: Maybe<Scalars["Boolean"]["output"]>;
  type: Profile_Type;
};

export type RelativeInput = {
  alias?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstname?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  image?: InputMaybe<Scalars["ID"]["input"]>;
  institution?: InputMaybe<Scalars["ID"]["input"]>;
  lastname?: InputMaybe<Scalars["String"]["input"]>;
  mobile?: InputMaybe<Scalars["String"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  user?: InputMaybe<Scalars["ID"]["input"]>;
};

export type Reminder = {
  __typename?: "Reminder";
  minutes: Scalars["Int"]["output"];
  title: Scalars["String"]["output"];
};

export type RepeatRule = {
  __typename?: "RepeatRule";
  description: Scalars["String"]["output"];
  rrule: Scalars["String"]["output"];
  /** Human-readable description of repeat rule, translated to institution's locale */
  rruleText: Scalars["String"]["output"];
};

export type RepeatingActivity = Node & {
  __typename?: "RepeatingActivity";
  cancelled: Scalars["Boolean"]["output"];
  club?: Maybe<Club>;
  coverImage?: Maybe<Picture>;
  departments?: Maybe<Array<Department>>;
  description?: Maybe<Scalars["String"]["output"]>;
  documents?: Maybe<Array<Document>>;
  duration?: Maybe<Scalars["Int"]["output"]>;
  endDate: Scalars["DateTime"]["output"];
  groups?: Maybe<Array<Group>>;
  id: Scalars["ID"]["output"];
  image?: Maybe<Picture>;
  institution: Institution;
  location?: Maybe<Scalars["String"]["output"]>;
  maxSignups?: Maybe<Scalars["Int"]["output"]>;
  notificationSettings?: Maybe<NotificationSettings>;
  notifyNokOnSignup: Scalars["Boolean"]["output"];
  participants?: Maybe<Array<Profile>>;
  participating: Scalars["Boolean"]["output"];
  pictograms?: Maybe<Array<Pictogram>>;
  pictures?: Maybe<Array<Picture>>;
  price?: Maybe<Array<EventPrice>>;
  read: Scalars["Boolean"]["output"];
  repeat: RepeatRule;
  responsible?: Maybe<Array<Profile>>;
  showDateEnd?: Maybe<Scalars["DateTime"]["output"]>;
  showDateStart?: Maybe<Scalars["DateTime"]["output"]>;
  showFrom?: Maybe<Scalars["Int"]["output"]>;
  signupClose?: Maybe<Scalars["Int"]["output"]>;
  signupEnabled: Scalars["Boolean"]["output"];
  signupEnd?: Maybe<Scalars["DateTime"]["output"]>;
  signupPossible: Scalars["Boolean"]["output"];
  signupStart?: Maybe<Scalars["DateTime"]["output"]>;
  startDate: Scalars["DateTime"]["output"];
  title: Scalars["String"]["output"];
  videos?: Maybe<Array<Video>>;
  withdrawPossible: Scalars["Boolean"]["output"];
};

export type RepeatingMeeting = Node & {
  __typename?: "RepeatingMeeting";
  agendaPoints?: Maybe<Array<MeetingAgendaPoint>>;
  cancelled: Scalars["Boolean"]["output"];
  category?: Maybe<Scalars["String"]["output"]>;
  coverImage?: Maybe<Picture>;
  departments?: Maybe<Array<Department>>;
  documents?: Maybe<Array<Document>>;
  endDate: Scalars["DateTime"]["output"];
  groups?: Maybe<Array<Group>>;
  id: Scalars["ID"]["output"];
  institution: Institution;
  location?: Maybe<Scalars["String"]["output"]>;
  participants?: Maybe<Array<Profile>>;
  participating: Scalars["Boolean"]["output"];
  pictograms?: Maybe<Array<Pictogram>>;
  pictures?: Maybe<Array<Picture>>;
  repeat: RepeatRule;
  signupEnabled: Scalars["Boolean"]["output"];
  signupPossible: Scalars["Boolean"]["output"];
  startDate: Scalars["DateTime"]["output"];
  suggestions?: Maybe<Array<MeetingSuggestion>>;
  title: Scalars["String"]["output"];
  videos?: Maybe<Array<Video>>;
  withdrawPossible: Scalars["Boolean"]["output"];
};

export type RepeatingPlannerTask = Node & {
  __typename?: "RepeatingPlannerTask";
  approvalState: Approval_State;
  assignees: Array<PlannerTaskAssignee>;
  category?: Maybe<PlannerCategory>;
  completed: Scalars["Boolean"]["output"];
  /** @deprecated Use assignees instead */
  department?: Maybe<Department>;
  description?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use assignees instead */
  employee?: Maybe<Employee>;
  endDate: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  institution: Institution;
  pictogram?: Maybe<Pictogram>;
  picture?: Maybe<Picture>;
  private: Scalars["Boolean"]["output"];
  repeat: RepeatRule;
  repeatId: Scalars["ID"]["output"];
  /** @deprecated Use assignees instead */
  resident?: Maybe<Resident>;
  startDate: Scalars["DateTime"]["output"];
  subtasks?: Maybe<Array<PlannerSubtask>>;
  title: Scalars["String"]["output"];
  visibleForResident?: Maybe<Scalars["Boolean"]["output"]>;
};

export type Resident = Node & {
  __typename?: "Resident";
  alias?: Maybe<Scalars["String"]["output"]>;
  birthday?: Maybe<Scalars["Date"]["output"]>;
  departments?: Maybe<Array<Department>>;
  description?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use image instead */
  displayImage: Scalars["String"]["output"];
  displayName: Scalars["String"]["output"];
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  hasAccess: Scalars["Boolean"]["output"];
  hasConnect: Scalars["Boolean"]["output"];
  homeDays?: Maybe<Array<Maybe<Day_Of_Week>>>;
  id: Scalars["ID"]["output"];
  image?: Maybe<Picture>;
  institution: Institution;
  invitationStatus?: Maybe<Invitation_Status>;
  isFavorite?: Maybe<Scalars["Boolean"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  mealDays?: Maybe<Array<Maybe<Day_Of_Week>>>;
  mobile?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  profileVideo?: Maybe<Video>;
  settings?: Maybe<ProfileSettings>;
  showBirthday?: Maybe<Scalars["Boolean"]["output"]>;
  showBirthyear?: Maybe<Scalars["Boolean"]["output"]>;
  showEmail?: Maybe<Scalars["Boolean"]["output"]>;
  showImage?: Maybe<Scalars["Boolean"]["output"]>;
  showInfoOnScreen?: Maybe<Scalars["Boolean"]["output"]>;
  showLastName?: Maybe<Scalars["Boolean"]["output"]>;
  showMobile?: Maybe<Scalars["Boolean"]["output"]>;
  surname?: Maybe<Scalars["String"]["output"]>;
  type: Profile_Type;
  /** @deprecated Use settings {videoCall} instead */
  videoCallEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  /** @deprecated Use settings {videoCall} instead */
  videoCallStatus?: Maybe<Video_Call_Status>;
};

export type ResidentInput = {
  alias?: InputMaybe<Scalars["String"]["input"]>;
  birthday?: InputMaybe<Scalars["DateTime"]["input"]>;
  department?: InputMaybe<Scalars["ID"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstname?: InputMaybe<Scalars["String"]["input"]>;
  homeDays?: InputMaybe<Array<InputMaybe<Day_Of_Week>>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  image?: InputMaybe<Scalars["ID"]["input"]>;
  institution?: InputMaybe<Scalars["ID"]["input"]>;
  invitationStatus?: InputMaybe<Invitation_Status>;
  lastname?: InputMaybe<Scalars["String"]["input"]>;
  mealDays?: InputMaybe<Array<InputMaybe<Day_Of_Week>>>;
  mobile?: InputMaybe<Scalars["String"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  profileVideo?: InputMaybe<Scalars["ID"]["input"]>;
  relatives?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  showBirthday?: InputMaybe<Scalars["Boolean"]["input"]>;
  showBirthyear?: InputMaybe<Scalars["Boolean"]["input"]>;
  showEmail?: InputMaybe<Scalars["Boolean"]["input"]>;
  showHomedays?: InputMaybe<Scalars["Boolean"]["input"]>;
  showImage?: InputMaybe<Scalars["Boolean"]["input"]>;
  showInfoOnScreen?: InputMaybe<Scalars["Int"]["input"]>;
  showLastname?: InputMaybe<Scalars["Boolean"]["input"]>;
  showMobile?: InputMaybe<Scalars["Boolean"]["input"]>;
  startScreen?: InputMaybe<Start_Screen>;
  status?: InputMaybe<Scalars["Boolean"]["input"]>;
  user?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ResourceInput = {
  body?: InputMaybe<Scalars["String"]["input"]>;
  container?: InputMaybe<Container_Type>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  institution?: InputMaybe<Scalars["ID"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type ResourceMutation = {
  __typename?: "ResourceMutation";
  save?: Maybe<DefaultResponse>;
};

export type ResourceMutationSaveArgs = {
  resource?: InputMaybe<ResourceInput>;
};

export enum Shift_Type {
  Normal = "NORMAL",
  Simple = "SIMPLE",
}

export enum Speaking_Rate {
  Fast = "FAST",
  Medium = "MEDIUM",
  Slow = "SLOW",
  XxFast = "XX_FAST",
  XxSlow = "XX_SLOW",
  XFast = "X_FAST",
  XSlow = "X_SLOW",
}

export enum Start_Screen {
  Modules = "MODULES",
  MyDay = "MY_DAY",
  WhatsHappening = "WHATS_HAPPENING",
}

export type ScheduleAvailableEmployee = {
  __typename?: "ScheduleAvailableEmployee";
  employeeId?: Maybe<Scalars["ID"]["output"]>;
  unavailableFrom?: Maybe<Scalars["DateTime"]["output"]>;
  unavailableReason?: Maybe<Scalars["String"]["output"]>;
  unavailableTo?: Maybe<Scalars["DateTime"]["output"]>;
};

export type ScheduleCalendar = {
  __typename?: "ScheduleCalendar";
  date: Scalars["Date"]["output"];
  empty?: Maybe<Scalars["Boolean"]["output"]>;
};

export type ScheduleQuery = {
  __typename?: "ScheduleQuery";
  shifts?: Maybe<Array<ScheduleShift>>;
};

export type ScheduleQueryShiftsArgs = {
  dateFrom: Scalars["Date"]["input"];
  dateTo: Scalars["Date"]["input"];
  filter: Filter;
  timezone?: Scalars["TimeZone"]["input"];
};

export type ScheduleShift = Node & {
  __typename?: "ScheduleShift";
  cancelled?: Maybe<Scalars["Boolean"]["output"]>;
  employee: Employee;
  end: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  nightShift?: Maybe<Scalars["Boolean"]["output"]>;
  start: Scalars["DateTime"]["output"];
  unavailable?: Maybe<Array<Maybe<UnavailableScheduleShift>>>;
};

export type ScheduleShiftInput = {
  cancelled?: InputMaybe<Scalars["Boolean"]["input"]>;
  employeeId?: InputMaybe<Scalars["ID"]["input"]>;
  id: Scalars["ID"]["input"];
};

export type ScreenProfile = {
  pin?: InputMaybe<Scalars["String"]["input"]>;
  profileId: Scalars["ID"]["input"];
  profileType: Profile_Type;
};

export type SelfMutation = {
  __typename?: "SelfMutation";
  /** @deprecated Use user { changeSettings } instead */
  changeCallStatus?: Maybe<DefaultResponse>;
};

export type SelfMutationChangeCallStatusArgs = {
  status?: InputMaybe<Video_Call_Status>;
};

export type SelfQuery = {
  __typename?: "SelfQuery";
  get: User;
  /** @deprecated Use groupType arg instead */
  groups: Array<Group>;
  institutions: Array<Institution>;
};

export type SelfQueryGroupsArgs = {
  groupType?: InputMaybe<Grouptype>;
  type: Group_Type;
};

export type Settings = {
  __typename?: "Settings";
  defaultDepartments?: Maybe<Array<Department>>;
  groups?: Maybe<Array<Group>>;
  institutions?: Maybe<Array<Institution>>;
  /** @deprecated Use profile { settings { notifications } } instead */
  notificationSetting: NotificationSetting;
  startScreen: Start_Screen;
  textToSpeechFilter: TextToSpeechFilter;
};

export type Shift = Node & {
  __typename?: "Shift";
  end?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  start?: Maybe<Scalars["DateTime"]["output"]>;
};

export type ShiftMutation = {
  __typename?: "ShiftMutation";
  delete?: Maybe<DefaultResponse>;
  update?: Maybe<DefaultResponse>;
};

export type ShiftMutationDeleteArgs = {
  id: Scalars["ID"]["input"];
};

export type ShiftMutationUpdateArgs = {
  shift: ScheduleShiftInput;
};

export type SimpleSchedule = {
  __typename?: "SimpleSchedule";
  employees?: Maybe<Array<Employee>>;
  end: Scalars["DateTime"]["output"];
  image?: Maybe<Pictogram>;
  start: Scalars["DateTime"]["output"];
  title: Scalars["String"]["output"];
};

export type SimpleSchedulePlanEntry = {
  __typename?: "SimpleSchedulePlanEntry";
  endtime: Scalars["DateTime"]["output"];
  icon?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  starttime: Scalars["DateTime"]["output"];
};

export type SingleActivity = Node & {
  __typename?: "SingleActivity";
  cancelled: Scalars["Boolean"]["output"];
  club?: Maybe<Club>;
  coverImage?: Maybe<Picture>;
  departments?: Maybe<Array<Department>>;
  description?: Maybe<Scalars["String"]["output"]>;
  documents?: Maybe<Array<Document>>;
  duration?: Maybe<Scalars["Int"]["output"]>;
  endDate: Scalars["DateTime"]["output"];
  groups?: Maybe<Array<Group>>;
  id: Scalars["ID"]["output"];
  image?: Maybe<Picture>;
  institution: Institution;
  location?: Maybe<Scalars["String"]["output"]>;
  maxSignups?: Maybe<Scalars["Int"]["output"]>;
  notificationSettings?: Maybe<NotificationSettings>;
  notifyNokOnSignup: Scalars["Boolean"]["output"];
  participants?: Maybe<Array<Profile>>;
  participating: Scalars["Boolean"]["output"];
  pictograms?: Maybe<Array<Pictogram>>;
  pictures?: Maybe<Array<Picture>>;
  price?: Maybe<Array<EventPrice>>;
  read: Scalars["Boolean"]["output"];
  responsible?: Maybe<Array<Profile>>;
  showDateEnd?: Maybe<Scalars["DateTime"]["output"]>;
  showDateStart?: Maybe<Scalars["DateTime"]["output"]>;
  showFrom?: Maybe<Scalars["Int"]["output"]>;
  signupClose?: Maybe<Scalars["Int"]["output"]>;
  signupEnabled: Scalars["Boolean"]["output"];
  signupEnd?: Maybe<Scalars["DateTime"]["output"]>;
  signupPossible: Scalars["Boolean"]["output"];
  signupStart?: Maybe<Scalars["DateTime"]["output"]>;
  startDate: Scalars["DateTime"]["output"];
  title: Scalars["String"]["output"];
  videos?: Maybe<Array<Video>>;
  withdrawPossible: Scalars["Boolean"]["output"];
};

export type SingleMeeting = Node & {
  __typename?: "SingleMeeting";
  agendaPoints?: Maybe<Array<MeetingAgendaPoint>>;
  cancelled: Scalars["Boolean"]["output"];
  category?: Maybe<Scalars["String"]["output"]>;
  coverImage?: Maybe<Picture>;
  departments?: Maybe<Array<Department>>;
  documents?: Maybe<Array<Document>>;
  endDate: Scalars["DateTime"]["output"];
  groups?: Maybe<Array<Group>>;
  id: Scalars["ID"]["output"];
  institution: Institution;
  location?: Maybe<Scalars["String"]["output"]>;
  participants?: Maybe<Array<Profile>>;
  participating: Scalars["Boolean"]["output"];
  pictograms?: Maybe<Array<Pictogram>>;
  pictures?: Maybe<Array<Picture>>;
  signupEnabled: Scalars["Boolean"]["output"];
  signupPossible: Scalars["Boolean"]["output"];
  startDate: Scalars["DateTime"]["output"];
  suggestions?: Maybe<Array<MeetingSuggestion>>;
  title: Scalars["String"]["output"];
  videos?: Maybe<Array<Video>>;
  withdrawPossible: Scalars["Boolean"]["output"];
};

export type SinglePlannerTask = Node & {
  __typename?: "SinglePlannerTask";
  approvalState: Approval_State;
  assignees: Array<PlannerTaskAssignee>;
  category?: Maybe<PlannerCategory>;
  completed: Scalars["Boolean"]["output"];
  /** @deprecated Use assignees instead */
  department?: Maybe<Department>;
  description?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use assignees instead */
  employee?: Maybe<Employee>;
  endDate: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  institution: Institution;
  pictogram?: Maybe<Pictogram>;
  picture?: Maybe<Picture>;
  private: Scalars["Boolean"]["output"];
  /** @deprecated Use assignees instead */
  resident?: Maybe<Resident>;
  startDate: Scalars["DateTime"]["output"];
  subtasks?: Maybe<Array<PlannerSubtask>>;
  title: Scalars["String"]["output"];
  visibleForResident?: Maybe<Scalars["Boolean"]["output"]>;
};

export enum Tts_Entity_Type {
  Activity = "ACTIVITY",
  /** @deprecated Use `BOOKING_RESOURCE` instead */
  Booking = "BOOKING",
  BookingBooking = "BOOKING_BOOKING",
  BookingResource = "BOOKING_RESOURCE",
  Employee = "EMPLOYEE",
  Meal = "MEAL",
  Meeting = "MEETING",
  News = "NEWS",
  PlannerAssignment = "PLANNER_ASSIGNMENT",
  Resident = "RESIDENT",
}

export type TextToSpeech = {
  __typename?: "TextToSpeech";
  /** An url to the required MP3 */
  source: Scalars["String"]["output"];
};

export type TextToSpeechFilter = {
  __typename?: "TextToSpeechFilter";
  enabled: Scalars["Boolean"]["output"];
  speakingRate: Speaking_Rate;
};

export type TravelPlan = {
  __typename?: "TravelPlan";
  cls?: Maybe<Scalars["String"]["output"]>;
  direction?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  rtTime?: Maybe<Scalars["String"]["output"]>;
  stop?: Maybe<Scalars["String"]["output"]>;
  time?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type TravelPlanLocation = {
  __typename?: "TravelPlanLocation";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export enum User_Roles {
  Admin = "ADMIN",
  Department = "DEPARTMENT",
  Employee = "EMPLOYEE",
  Nok = "NOK",
  Resident = "RESIDENT",
  Superuser = "SUPERUSER",
  Support = "SUPPORT",
  System = "SYSTEM",
}

export type UnavailableScheduleShift = {
  __typename?: "UnavailableScheduleShift";
  description?: Maybe<Scalars["String"]["output"]>;
  employee: Employee;
  end?: Maybe<Scalars["DateTime"]["output"]>;
  start?: Maybe<Scalars["DateTime"]["output"]>;
};

export type User = {
  __typename?: "User";
  email?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  profiles?: Maybe<Array<Profile>>;
  settings: UserSettings;
  username: Scalars["String"]["output"];
};

export type UserInput = {
  id: Scalars["ID"]["input"];
  settings?: InputMaybe<UserSettingsInput>;
};

export type UserMutation = {
  __typename?: "UserMutation";
  changeSettings?: Maybe<DefaultResponse>;
  deleteUser?: Maybe<DefaultResponse>;
  invalidateAccessTokens?: Maybe<DefaultResponse>;
  saveContacts?: Maybe<DefaultResponse>;
  saveProfile?: Maybe<DefaultResponse>;
  saveUser?: Maybe<DefaultResponse>;
  /** @deprecated use saveUser instead */
  update: DefaultResponse;
};

export type UserMutationChangeSettingsArgs = {
  profile?: InputMaybe<ProfileInput>;
  settings?: InputMaybe<ProfileSettingsInput>;
};

export type UserMutationDeleteUserArgs = {
  id: Scalars["ID"]["input"];
};

export type UserMutationInvalidateAccessTokensArgs = {
  institutions?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  profileTypes?: InputMaybe<Array<Profile_Type>>;
  uids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type UserMutationSaveContactsArgs = {
  contacts: Array<Scalars["ID"]["input"]>;
  uid?: InputMaybe<Scalars["ID"]["input"]>;
};

export type UserMutationSaveProfileArgs = {
  employee?: InputMaybe<EmployeeInput>;
  relative?: InputMaybe<RelativeInput>;
  resident?: InputMaybe<ResidentInput>;
};

export type UserMutationSaveUserArgs = {
  user: UserSaveInput;
};

export type UserMutationUpdateArgs = {
  user: UserInput;
};

export type UserQuery = {
  __typename?: "UserQuery";
  get?: Maybe<Array<Maybe<User>>>;
  getAccessToken?: Maybe<AccessToken>;
  getCalls?: Maybe<Array<Maybe<Call>>>;
  getContacts?: Maybe<Array<Maybe<User>>>;
  validatePin?: Maybe<DefaultResponse>;
};

export type UserQueryGetArgs = {
  ids: Array<Scalars["ID"]["input"]>;
};

export type UserQueryGetAccessTokenArgs = {
  ttl?: InputMaybe<Scalars["Int"]["input"]>;
  uid: Scalars["ID"]["input"];
};

export type UserQueryGetCallsArgs = {
  callIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type UserQueryGetContactsArgs = {
  accessCheck?: InputMaybe<Scalars["Boolean"]["input"]>;
  uid?: InputMaybe<Scalars["ID"]["input"]>;
};

export type UserQueryValidatePinArgs = {
  profile?: InputMaybe<ScreenProfile>;
};

export type UserRoleInput = {
  institution: Scalars["ID"]["input"];
  role: User_Roles;
};

export type UserSaveInput = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  roles?: InputMaybe<Array<UserRoleInput>>;
  settings?: InputMaybe<UserSettingsInput>;
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserSettings = {
  __typename?: "UserSettings";
  policies: UserSettingsPolicies;
};

export type UserSettingsInput = {
  policies: UserSettingsPoliciesInput;
};

export type UserSettingsPolicies = {
  __typename?: "UserSettingsPolicies";
  groupPolicyAccepted: Scalars["Boolean"]["output"];
  privacyPolicyAccepted: Scalars["Boolean"]["output"];
};

export type UserSettingsPoliciesInput = {
  groupPolicyAccepted: Scalars["Boolean"]["input"];
  privacyPolicyAccepted: Scalars["Boolean"]["input"];
};

/** The status in VideoKald */
export enum Video_Call_Status {
  Busy = "BUSY",
  Offline = "OFFLINE",
  Online = "ONLINE",
}

/** A list of the different allowed video providers */
export enum Video_Provider {
  Dreambroker = "DREAMBROKER",
  Vimeo = "VIMEO",
  Youtube = "YOUTUBE",
}

export type Video = Node & {
  __typename?: "Video";
  blurhash?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  provider?: Maybe<Video_Provider>;
  thumbnail?: Maybe<Scalars["String"]["output"]>;
  url: Scalars["String"]["output"];
};

/** A calendar entry for the what's happening screen. It takes into account if there are no events on the specified dates (empty = true) */
export type WhatsHappeningCalendar = {
  __typename?: "WhatsHappeningCalendar";
  date: Scalars["Date"]["output"];
  empty?: Maybe<Scalars["Boolean"]["output"]>;
};

type Activity_RepeatingActivity_Fragment = {
  __typename?: "RepeatingActivity";
  id: string;
  title: string;
  signupPossible: boolean;
  signupStart?: any | null;
  signupEnd?: any | null;
  startDate: any;
  endDate: any;
  showDateStart?: any | null;
  showDateEnd?: any | null;
  maxSignups?: number | null;
  cancelled: boolean;
  read: boolean;
  description?: string | null;
  participating: boolean;
  repeatRule: {
    __typename?: "RepeatRule";
    rrule: string;
    rruleText: string;
    description: string;
  };
  notificationSettings?: {
    __typename?: "NotificationSettings";
    status: boolean;
    reminderFirst?: number | null;
    reminderSecond?: number | null;
  } | null;
  documents?: Array<{ __typename?: "Document" } & DocumentFragment> | null;
  institution: { __typename?: "Institution"; id: string };
  videos?: Array<{ __typename?: "Video" } & VideoFragment> | null;
  club?: ({ __typename?: "Club" } & ClubFragment) | null;
  price?: Array<{ __typename?: "EventPrice" } & PriceFragment> | null;
  responsible?: Array<
    | ({ __typename?: "Employee" } & CompactProfile_Employee_Fragment)
    | ({ __typename?: "Relative" } & CompactProfile_Relative_Fragment)
    | ({ __typename?: "Resident" } & CompactProfile_Resident_Fragment)
  > | null;
  coverImage?: ({ __typename?: "Picture" } & PictureFragment) | null;
  participants?: Array<
    | { __typename?: "Employee"; id: string }
    | { __typename?: "Relative"; id: string }
    | { __typename?: "Resident"; id: string }
  > | null;
};

type Activity_SingleActivity_Fragment = {
  __typename?: "SingleActivity";
  id: string;
  title: string;
  signupPossible: boolean;
  signupStart?: any | null;
  signupEnd?: any | null;
  startDate: any;
  endDate: any;
  showDateStart?: any | null;
  showDateEnd?: any | null;
  maxSignups?: number | null;
  cancelled: boolean;
  read: boolean;
  description?: string | null;
  notificationSettings?: {
    __typename?: "NotificationSettings";
    status: boolean;
    reminderFirst?: number | null;
    reminderSecond?: number | null;
  } | null;
  documents?: Array<{ __typename?: "Document" } & DocumentFragment> | null;
  institution: { __typename?: "Institution"; id: string };
  club?: ({ __typename?: "Club" } & ClubFragment) | null;
  price?: Array<{ __typename?: "EventPrice" } & PriceFragment> | null;
  coverImage?: ({ __typename?: "Picture" } & PictureFragment) | null;
  responsible?: Array<
    | ({ __typename?: "Employee" } & CompactProfile_Employee_Fragment)
    | ({ __typename?: "Relative" } & CompactProfile_Relative_Fragment)
    | ({ __typename?: "Resident" } & CompactProfile_Resident_Fragment)
  > | null;
  participants?: Array<
    | ({ __typename?: "Employee" } & Participants_Employee_Fragment)
    | ({ __typename?: "Relative" } & Participants_Relative_Fragment)
    | ({ __typename?: "Resident" } & Participants_Resident_Fragment)
  > | null;
  videos?: Array<{ __typename?: "Video" } & VideoFragment> | null;
};

export type ActivityFragment =
  | Activity_RepeatingActivity_Fragment
  | Activity_SingleActivity_Fragment;

export type ClubFragment = {
  __typename?: "Club";
  id: string;
  name: string;
  color?: any | null;
  logo: { __typename?: "Picture" } & PictureFragment;
};

export type CompactDepartmentFragment = {
  __typename?: "Department";
  id: string;
  institutionId: string;
  name: string;
  color?: any | null;
  screenOnly: boolean;
};

export type CompactDocumentFragment = {
  __typename?: "Document";
  id: string;
  url: string;
  title: string;
};

export type CompactInstitutionFragment = {
  __typename?: "Institution";
  id: string;
  name: string;
  color?: any | null;
  locale?: string | null;
};

export type DepartmentFragment = {
  __typename?: "Department";
  id: string;
  institutionId: string;
  name: string;
  color?: any | null;
  screenOnly: boolean;
  settings?: {
    __typename?: "DepartmentSettings";
    meal?: {
      __typename?: "DepartmentMealSettings";
      daysForward: number;
      signup: boolean;
    } | null;
    shift?: {
      __typename?: "DepartmentShiftSettings";
      dayDisplay?: number | null;
      daysForward?: number | null;
      showGraphics?: boolean | null;
      showCancelled?: boolean | null;
    } | null;
    appConfig?: {
      __typename?: "DepartmentAppConfig";
      modulesList?: Array<Module_Type> | null;
    } | null;
    simpleSchedulePlan?: Array<{
      __typename?: "SimpleSchedulePlanEntry";
      name: string;
      icon?: string | null;
      startTime: any;
      endTime: any;
    }> | null;
  } | null;
};

export type DocumentFragment = {
  __typename?: "Document";
  id: string;
  url: string;
  title: string;
  description?: string | null;
};

export type InstitutionFragment = {
  __typename?: "Institution";
  id: string;
  name: string;
  color?: any | null;
  locale?: string | null;
  departments?: Array<
    { __typename?: "Department" } & DepartmentFragment
  > | null;
  settings: {
    __typename?: "InstitutionSettings";
    useExternalMedia: boolean;
    showAllDepartments: boolean;
    planner: {
      __typename?: "InstitutionSettingPlanner";
      enabledEmployees: boolean;
      enabledResidents: boolean;
    };
  };
};

export type NewsFragment = {
  __typename?: "News";
  id: string;
  title: string;
  body: string;
  startDate: any;
  endDate: any;
  published?: any | null;
  read: boolean;
  coverImage?: ({ __typename?: "Picture" } & PictureFragment) | null;
  pictograms?: Array<{ __typename?: "Pictogram" } & PictogramFragment> | null;
  pictures?: Array<{ __typename?: "Picture" } & PictureFragment> | null;
  videos?: Array<{ __typename?: "Video" } & VideoFragment> | null;
  documents?: Array<{ __typename?: "Document" } & DocumentFragment> | null;
  departments?: Array<
    { __typename?: "Department" } & CompactDepartmentFragment
  > | null;
  institution: { __typename?: "Institution" } & CompactInstitutionFragment;
};

export type NotificationSettingsFragment = {
  __typename?: "NotificationSettings";
  reminderFirst?: number | null;
  reminderSecond?: number | null;
  status: boolean;
  triggerWhenCancelled: boolean;
  triggerWhenTimeChange: boolean;
};

type Participants_Employee_Fragment = {
  __typename?: "Employee";
  id: string;
  type: Profile_Type;
};

type Participants_Relative_Fragment = {
  __typename?: "Relative";
  id: string;
  type: Profile_Type;
};

type Participants_Resident_Fragment = {
  __typename?: "Resident";
  id: string;
  type: Profile_Type;
};

export type ParticipantsFragment =
  | Participants_Employee_Fragment
  | Participants_Relative_Fragment
  | Participants_Resident_Fragment;

export type PictogramFragment = {
  __typename?: "Pictogram";
  id: string;
  description?: string | null;
  source: string;
  type?: Pictogram_Type | null;
};

export type PictureFragment = {
  __typename?: "Picture";
  id: string;
  description?: string | null;
  source: string;
  inMyArchive?: boolean | null;
};

export type PriceFragment = {
  __typename?: "EventPrice";
  price?: number | null;
  currency: string;
  profileType: Profile_Type;
  description?: string | null;
};

export type CompactEmployeeFragment = {
  __typename?: "Employee";
  id: string;
  type: Profile_Type;
  displayName: string;
  displayImage: string;
  showImage?: boolean | null;
  phone?: string | null;
  title?: string | null;
};

export type CompactResidentFragment = {
  __typename?: "Resident";
  id: string;
  type: Profile_Type;
  displayName: string;
  displayImage: string;
  showImage?: boolean | null;
};

export type RelativeFragment = {
  __typename?: "Relative";
  id: string;
  type: Profile_Type;
  displayName: string;
  displayImage: string;
  showImage?: boolean | null;
  email?: string | null;
  mobile?: string | null;
};

type CompactProfile_Employee_Fragment = {
  __typename?: "Employee";
} & CompactEmployeeFragment;

type CompactProfile_Relative_Fragment = {
  __typename?: "Relative";
} & RelativeFragment;

type CompactProfile_Resident_Fragment = {
  __typename?: "Resident";
} & CompactResidentFragment;

export type CompactProfileFragment =
  | CompactProfile_Employee_Fragment
  | CompactProfile_Relative_Fragment
  | CompactProfile_Resident_Fragment;

export type VideoFragment = {
  __typename?: "Video";
  id: string;
  provider?: Video_Provider | null;
  url: string;
  description?: string | null;
  thumbnail?: string | null;
};

export type DeleteActivityMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteActivityMutation = {
  __typename?: "Mutation";
  activity?: {
    __typename?: "ActivityMutation";
    delete?: {
      __typename?: "DefaultResponse";
      id?: string | null;
      success: boolean;
      message?: string | null;
    } | null;
  } | null;
};

export type SaveActivityMutationVariables = Exact<{
  activity: ActivityInput;
}>;

export type SaveActivityMutation = {
  __typename?: "Mutation";
  activity?: {
    __typename?: "ActivityMutation";
    save?: {
      __typename?: "DefaultResponse";
      id?: string | null;
      success: boolean;
      message?: string | null;
    } | null;
  } | null;
};

export type SaveActivityNotificationDocumentMutationVariables = Exact<{
  settings: NotificationSettingsInput;
}>;

export type SaveActivityNotificationDocumentMutation = {
  __typename?: "Mutation";
  activity?: {
    __typename?: "ActivityMutation";
    saveNotificationSettings?: {
      __typename?: "DefaultResponse";
      id?: string | null;
      message?: string | null;
      success: boolean;
    } | null;
  } | null;
};

export type SaveResourceMutationVariables = Exact<{
  resource: ResourceInput;
}>;

export type SaveResourceMutation = {
  __typename?: "Mutation";
  resource?: {
    __typename?: "ResourceMutation";
    save?: {
      __typename?: "DefaultResponse";
      id?: string | null;
      success: boolean;
      message?: string | null;
    } | null;
  } | null;
};

export type EditDepartmentMutationVariables = Exact<{
  department: DepartmentInput;
}>;

export type EditDepartmentMutation = {
  __typename?: "Mutation";
  editDepartment?: {
    __typename?: "DefaultResponse";
    id?: string | null;
    message?: string | null;
    success: boolean;
  } | null;
};

export type SaveEmployeeMutationVariables = Exact<{
  employee: EmployeeInput;
}>;

export type SaveEmployeeMutation = {
  __typename?: "Mutation";
  user?: {
    __typename?: "UserMutation";
    saveProfile?: {
      __typename?: "DefaultResponse";
      id?: string | null;
      success: boolean;
      message?: string | null;
    } | null;
  } | null;
};

export type DeleteNewsMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteNewsMutation = {
  __typename?: "Mutation";
  news?: {
    __typename?: "NewsMutation";
    delete?: {
      __typename?: "DefaultResponse";
      id?: string | null;
      success: boolean;
      message?: string | null;
    } | null;
  } | null;
};

export type SaveNewsMutationVariables = Exact<{
  news: NewsInput;
}>;

export type SaveNewsMutation = {
  __typename?: "Mutation";
  news?: {
    __typename?: "NewsMutation";
    save?: {
      __typename?: "DefaultResponse";
      id?: string | null;
      success: boolean;
      message?: string | null;
    } | null;
  } | null;
};

export type SaveProfileMutationVariables = Exact<{
  employee: EmployeeInput;
}>;

export type SaveProfileMutation = {
  __typename?: "Mutation";
  user?: {
    __typename?: "UserMutation";
    saveProfile?: {
      __typename?: "DefaultResponse";
      id?: string | null;
      success: boolean;
      message?: string | null;
    } | null;
  } | null;
};

export type DeleteUserMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteUserMutation = {
  __typename?: "Mutation";
  user?: {
    __typename?: "UserMutation";
    deleteUser?: {
      __typename?: "DefaultResponse";
      id?: string | null;
      message?: string | null;
      success: boolean;
    } | null;
  } | null;
};

export type SaveUserMutationVariables = Exact<{
  user: UserSaveInput;
}>;

export type SaveUserMutation = {
  __typename?: "Mutation";
  user?: {
    __typename?: "UserMutation";
    saveUser?: {
      __typename?: "DefaultResponse";
      id?: string | null;
      message?: string | null;
      success: boolean;
    } | null;
  } | null;
};

export type GetActivitiesByRangeQueryVariables = Exact<{
  filter: Filter;
  dateFrom: Scalars["DateTime"]["input"];
  dateTo: Scalars["DateTime"]["input"];
  visibility?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GetActivitiesByRangeQuery = {
  __typename?: "Query";
  activity?: {
    __typename?: "ActivityQuery";
    getActivitiesByFilters?: Array<
      | ({
          __typename?: "RepeatingActivity";
        } & Activity_RepeatingActivity_Fragment)
      | ({ __typename?: "SingleActivity" } & Activity_SingleActivity_Fragment)
    > | null;
  } | null;
};

export type GetDepartmentQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GetDepartmentQuery = {
  __typename?: "Query";
  department?: {
    __typename?: "Department";
    settings?: {
      __typename?: "DepartmentSettings";
      appConfig?: {
        __typename?: "DepartmentAppConfig";
        modulesList?: Array<Module_Type> | null;
      } | null;
      screenConfig?: {
        __typename?: "DepartmentScreenConfig";
        footerConfig?: Array<Module_List | null> | null;
        gridSize?: number | null;
        modules?: Array<{
          __typename?: "ModuleForScreen";
          colSpan?: string | null;
          id: string;
          rowSpan?: string | null;
          type?: Module_List | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type InstitutionByIdQueryQueryVariables = Exact<{
  ids?: InputMaybe<
    | Array<InputMaybe<Scalars["ID"]["input"]>>
    | InputMaybe<Scalars["ID"]["input"]>
  >;
}>;

export type InstitutionByIdQueryQuery = {
  __typename?: "Query";
  institutions?: {
    __typename?: "InstitutionQuery";
    get?: Array<{
      __typename?: "Institution";
      name: string;
      id: string;
      departments?: Array<{
        __typename?: "Department";
        name: string;
        id: string;
      }> | null;
    }> | null;
  } | null;
};

export type InstitutionQueryQueryVariables = Exact<{
  ids?: InputMaybe<
    | Array<InputMaybe<Scalars["ID"]["input"]>>
    | InputMaybe<Scalars["ID"]["input"]>
  >;
}>;

export type InstitutionQueryQuery = {
  __typename?: "Query";
  institutions?: {
    __typename?: "InstitutionQuery";
    get?: Array<{
      __typename?: "Institution";
      name: string;
      id: string;
    }> | null;
  } | null;
};

export type GetDepartmentListQueryVariables = Exact<{ [key: string]: never }>;

export type GetDepartmentListQuery = {
  __typename?: "Query";
  institutions?: {
    __typename?: "InstitutionQuery";
    getMyInstitution: {
      __typename?: "Institution";
      id: string;
      departments?: Array<{
        __typename?: "Department";
        id: string;
        name: string;
        color?: any | null;
      }> | null;
    };
  } | null;
};

export type InstitutionsQueryVariables = Exact<{ [key: string]: never }>;

export type InstitutionsQuery = {
  __typename?: "Query";
  institutions?: {
    __typename?: "InstitutionQuery";
    getMyInstitution: {
      __typename?: "Institution";
      id: string;
      departments?: Array<{
        __typename?: "Department";
        id: string;
        employees?: Array<{
          __typename?: "Employee";
          id: string;
          name?: string | null;
          surname?: string | null;
          email?: string | null;
          displayName: string;
          firstName?: string | null;
          lastName?: string | null;
          mobile?: string | null;
          phone?: string | null;
          title?: string | null;
          type: Profile_Type;
          alias?: string | null;
          description?: string | null;
          showImage?: boolean | null;
          isSuperuser?: boolean | null;
          image?: { __typename?: "Picture"; id: string; source: string } | null;
          user?: {
            __typename?: "User";
            id: string;
            email?: string | null;
            username: string;
          } | null;
        }> | null;
      }> | null;
    };
  } | null;
};

export type GetNewsByFiltersQueryVariables = Exact<{
  dateFrom: Scalars["DateTime"]["input"];
  dateTo: Scalars["DateTime"]["input"];
  filter: Filter;
  visibility?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GetNewsByFiltersQuery = {
  __typename?: "Query";
  newsItem?: {
    __typename?: "NewsQuery";
    getNewsByFilters?: Array<{ __typename?: "News" } & NewsFragment> | null;
  } | null;
};

export type NewsEntryQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type NewsEntryQuery = {
  __typename?: "Query";
  newsEntry?: ({ __typename?: "News" } & NewsFragment) | null;
};

export type AuditLogQueryQueryVariables = Exact<{
  filters?: InputMaybe<AuditLogFilter>;
}>;

export type AuditLogQueryQuery = {
  __typename?: "Query";
  auditLog?: {
    __typename?: "AuditLogQuery";
    getByFilters: Array<{
      __typename?: "AuditLog";
      created: any;
      entityId: string;
      entityType: string;
      event: Audit_Event_Type;
      profileId?: string | null;
      profileType?: Profile_Type | null;
    }>;
  } | null;
};

export type PlannerTaskActionResponseFragment = {
  __typename?: "PlannerTaskActionResponse";
  id?: string | null;
  success: boolean;
  message?: string | null;
};

export type CreatePlannerTaskMutationVariables = Exact<{
  plannerTask: PlannerTaskCreateInput;
}>;

export type CreatePlannerTaskMutation = {
  __typename?: "Mutation";
  createPlannerTask?:
    | ({
        __typename?: "PlannerTaskActionResponse";
      } & PlannerTaskActionResponseFragment)
    | null;
};

export type DeleteTaskMutationVariables = Exact<{
  input: DeletePlannerTaskInput;
}>;

export type DeleteTaskMutation = {
  __typename?: "Mutation";
  deletePlannerTask?: {
    __typename?: "DefaultResponse";
    id?: string | null;
    success: boolean;
    message?: string | null;
  } | null;
};

export type EditPlannerTaskMutationVariables = Exact<{
  plannerTaskId: Scalars["ID"]["input"];
  plannerTask: PlannerTaskEditInput;
}>;

export type EditPlannerTaskMutation = {
  __typename?: "Mutation";
  editPlannerTask?:
    | ({
        __typename?: "PlannerTaskActionResponse";
      } & PlannerTaskActionResponseFragment)
    | null;
};

export type EntitiesAvailabilityQueryVariables = Exact<{
  filter: Filter;
  dateFrom: Scalars["Date"]["input"];
  dateTo: Scalars["Date"]["input"];
  timezone: Scalars["TimeZone"]["input"];
}>;

export type EntitiesAvailabilityQuery = {
  __typename?: "Query";
  meetingRange?: Array<
    | {
        __typename?: "RepeatingMeeting";
        id: string;
        title: string;
        startDate: any;
        endDate: any;
      }
    | {
        __typename?: "SingleMeeting";
        id: string;
        title: string;
        startDate: any;
        endDate: any;
      }
  > | null;
  activityRange?: Array<
    | {
        __typename?: "RepeatingActivity";
        id: string;
        title: string;
        startDate: any;
        endDate: any;
      }
    | {
        __typename?: "SingleActivity";
        id: string;
        title: string;
        startDate: any;
        endDate: any;
      }
  > | null;
  mealRange?: Array<{
    __typename?: "Meal";
    id: string;
    title: string;
    startDate: any;
    endDate: any;
  }> | null;
};

export type GetActorsQueryVariables = Exact<{
  filter: Filter;
}>;

export type GetActorsQuery = {
  __typename?: "Query";
  profiles?: Array<
    | {
        __typename?: "Employee";
        id: string;
        displayImage: string;
        displayName: string;
        firstName?: string | null;
        lastName?: string | null;
        type: Profile_Type;
        settings?: {
          __typename?: "ProfileSettings";
          planner?: {
            __typename?: "ProfilePlannerSettings";
            assignment?: {
              __typename?: "ProfilePlannerAssignmentSettings";
              inbox: boolean;
            } | null;
          } | null;
        } | null;
      }
    | { __typename?: "Relative" }
    | {
        __typename?: "Resident";
        id: string;
        displayImage: string;
        displayName: string;
        firstName?: string | null;
        lastName?: string | null;
        type: Profile_Type;
        settings?: {
          __typename?: "ProfileSettings";
          planner?: {
            __typename?: "ProfilePlannerSettings";
            assignment?: {
              __typename?: "ProfilePlannerAssignmentSettings";
              inbox: boolean;
            } | null;
          } | null;
        } | null;
      }
  > | null;
  institution: {
    __typename?: "Institution";
    departments?: Array<{
      __typename?: "Department";
      color?: any | null;
      id: string;
      name: string;
      screenOnly: boolean;
    }> | null;
  };
};

export type GetPlannerTasksQueryVariables = Exact<{
  dateFrom: Scalars["DateTime"]["input"];
  dateTo: Scalars["DateTime"]["input"];
}>;

export type GetPlannerTasksQuery = {
  __typename?: "Query";
  planner?: {
    __typename?: "PlannerQuery";
    getTasksByFilters?: Array<
      | ({
          __typename?: "RepeatingPlannerTask";
        } & PlannerTask_RepeatingPlannerTask_Fragment)
      | ({
          __typename?: "SinglePlannerTask";
        } & PlannerTask_SinglePlannerTask_Fragment)
    > | null;
  } | null;
};

type PlannerTask_RepeatingPlannerTask_Fragment = {
  __typename?: "RepeatingPlannerTask";
  id: string;
  title: string;
  description?: string | null;
  visibleForResident?: boolean | null;
  completed: boolean;
  startDate: any;
  endDate: any;
  approvalState: Approval_State;
  repeatId: string;
  type: "RepeatingPlannerTask";
  assignees: Array<
    | { __typename?: "Department"; id: string; type: "Department" }
    | { __typename?: "Employee"; id: string; type: "Employee" }
    | { __typename?: "Resident"; id: string; type: "Resident" }
  >;
  picture?: { __typename?: "Picture"; source: string } | null;
  pictogram?: { __typename?: "Pictogram"; source: string } | null;
  subtasks?: Array<{
    __typename?: "PlannerSubtask";
    completed: boolean;
  }> | null;
  category?: {
    __typename?: "PlannerCategory";
    id: string;
    color: any;
    title: string;
  } | null;
};

type PlannerTask_SinglePlannerTask_Fragment = {
  __typename?: "SinglePlannerTask";
  id: string;
  title: string;
  description?: string | null;
  visibleForResident?: boolean | null;
  completed: boolean;
  startDate: any;
  endDate: any;
  approvalState: Approval_State;
  type: "SinglePlannerTask";
  assignees: Array<
    | { __typename?: "Department"; id: string; type: "Department" }
    | { __typename?: "Employee"; id: string; type: "Employee" }
    | { __typename?: "Resident"; id: string; type: "Resident" }
  >;
  picture?: { __typename?: "Picture"; source: string } | null;
  pictogram?: { __typename?: "Pictogram"; source: string } | null;
  subtasks?: Array<{
    __typename?: "PlannerSubtask";
    completed: boolean;
    id: string;
    title: string;
  }> | null;
  category?: {
    __typename?: "PlannerCategory";
    color: any;
    id: string;
    title: string;
  } | null;
};

export type PlannerTaskFragment =
  | PlannerTask_RepeatingPlannerTask_Fragment
  | PlannerTask_SinglePlannerTask_Fragment;

export type PlannerCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type PlannerCategoriesQuery = {
  __typename?: "Query";
  plannerCategories?: Array<{
    __typename?: "PlannerCategory";
    id: string;
    title: string;
    color: any;
  }> | null;
};

export type ScheduleAvailableEmployeesQueryVariables = Exact<{
  filter: Filter;
  dateFrom: Scalars["DateTime"]["input"];
  dateTo: Scalars["DateTime"]["input"];
}>;

export type ScheduleAvailableEmployeesQuery = {
  __typename?: "Query";
  scheduleAvailableEmployees?: Array<{
    __typename?: "ScheduleAvailableEmployee";
    employeeId?: string | null;
    unavailableFrom?: any | null;
    unavailableTo?: any | null;
    unavailableReason?: string | null;
  }> | null;
};

export type SettingsQueryVariables = Exact<{ [key: string]: never }>;

export type SettingsQuery = {
  __typename?: "Query";
  settings?: {
    __typename?: "Settings";
    institutions?: Array<{
      __typename?: "Institution";
      id: string;
      settings: {
        __typename?: "InstitutionSettings";
        vismaGatWs: boolean;
        planner: {
          __typename?: "InstitutionSettingPlanner";
          manager?: {
            __typename?: "InstitutionSettingPlannerManager";
            showOverdue?: boolean | null;
          } | null;
        };
      };
    }> | null;
  } | null;
};

export type GetProfilesQueryVariables = Exact<{
  filter: Filter;
}>;

export type GetProfilesQuery = {
  __typename?: "Query";
  profiles?: Array<
    | {
        __typename?: "Employee";
        id: string;
        displayName: string;
        type: Profile_Type;
        image?: { __typename?: "Picture"; source: string } | null;
        departments?: Array<{
          __typename?: "Department";
          id: string;
          name: string;
          color?: any | null;
        }> | null;
      }
    | { __typename?: "Relative" }
    | {
        __typename?: "Resident";
        id: string;
        displayName: string;
        type: Profile_Type;
        image?: { __typename?: "Picture"; source: string } | null;
        departments?: Array<{
          __typename?: "Department";
          id: string;
          name: string;
          color?: any | null;
        }> | null;
      }
  > | null;
};

export type DeleteUserByUsernameMutationVariables = Exact<{
  username: Scalars["String"]["input"];
}>;

export type DeleteUserByUsernameMutation = {
  __typename?: "Mutation";
  deleteUser?: {
    __typename?: "DefaultResponse";
    id?: string | null;
    success: boolean;
    message?: string | null;
  } | null;
};

export type GetSelfQueryQueryVariables = Exact<{ [key: string]: never }>;

export type GetSelfQueryQuery = {
  __typename?: "Query";
  self?: {
    __typename?: "SelfQuery";
    get: {
      __typename?: "User";
      id: string;
      profiles?: Array<
        | {
            __typename?: "Employee";
            id: string;
            institution: {
              __typename?: "Institution";
              id: string;
              notificationSettings?: {
                __typename?: "InstitutionNotificationSettings";
                activity?:
                  | ({
                      __typename?: "NotificationSettings";
                    } & NotificationSettingsFragment)
                  | null;
              } | null;
            };
          }
        | { __typename?: "Relative" }
        | { __typename?: "Resident" }
      > | null;
    };
  } | null;
};

export const DocumentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "document" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Document" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DocumentFragment, unknown>;
export const PictureFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "picture" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Picture" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "source" } },
          { kind: "Field", name: { kind: "Name", value: "inMyArchive" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PictureFragment, unknown>;
export const ClubFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "club" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Club" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "logo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "picture" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "picture" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Picture" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "source" } },
          { kind: "Field", name: { kind: "Name", value: "inMyArchive" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClubFragment, unknown>;
export const PriceFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "price" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "EventPrice" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "price" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "profileType" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PriceFragment, unknown>;
export const CompactEmployeeFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "compactEmployee" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Employee" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "displayName" } },
          { kind: "Field", name: { kind: "Name", value: "displayImage" } },
          { kind: "Field", name: { kind: "Name", value: "showImage" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompactEmployeeFragment, unknown>;
export const CompactResidentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "compactResident" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Resident" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "displayName" } },
          { kind: "Field", name: { kind: "Name", value: "displayImage" } },
          { kind: "Field", name: { kind: "Name", value: "showImage" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompactResidentFragment, unknown>;
export const RelativeFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "relative" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Relative" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "displayName" } },
          { kind: "Field", name: { kind: "Name", value: "displayImage" } },
          { kind: "Field", name: { kind: "Name", value: "showImage" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "mobile" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RelativeFragment, unknown>;
export const CompactProfileFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "compactProfile" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Profile" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "compactEmployee" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "compactResident" },
          },
          { kind: "FragmentSpread", name: { kind: "Name", value: "relative" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "compactEmployee" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Employee" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "displayName" } },
          { kind: "Field", name: { kind: "Name", value: "displayImage" } },
          { kind: "Field", name: { kind: "Name", value: "showImage" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "compactResident" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Resident" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "displayName" } },
          { kind: "Field", name: { kind: "Name", value: "displayImage" } },
          { kind: "Field", name: { kind: "Name", value: "showImage" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "relative" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Relative" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "displayName" } },
          { kind: "Field", name: { kind: "Name", value: "displayImage" } },
          { kind: "Field", name: { kind: "Name", value: "showImage" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "mobile" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompactProfileFragment, unknown>;
export const ParticipantsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "participants" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Profile" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "Employee" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "Relative" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "Resident" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ParticipantsFragment, unknown>;
export const VideoFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "video" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Video" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "provider" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "thumbnail" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VideoFragment, unknown>;
export const ActivityFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "activity" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Activity" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "SingleActivity" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "signupPossible" },
                },
                { kind: "Field", name: { kind: "Name", value: "signupStart" } },
                { kind: "Field", name: { kind: "Name", value: "signupEnd" } },
                { kind: "Field", name: { kind: "Name", value: "startDate" } },
                { kind: "Field", name: { kind: "Name", value: "endDate" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "showDateStart" },
                },
                { kind: "Field", name: { kind: "Name", value: "showDateEnd" } },
                { kind: "Field", name: { kind: "Name", value: "maxSignups" } },
                { kind: "Field", name: { kind: "Name", value: "cancelled" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "signupPossible" },
                },
                { kind: "Field", name: { kind: "Name", value: "read" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "notificationSettings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reminderFirst" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reminderSecond" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "documents" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "document" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "institution" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "club" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "club" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "price" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "price" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "coverImage" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "picture" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "responsible" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "compactProfile" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "participants" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "participants" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "videos" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "video" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "RepeatingActivity" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "repeatRule" },
                  name: { kind: "Name", value: "repeat" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "rrule" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rruleText" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "signupPossible" },
                },
                { kind: "Field", name: { kind: "Name", value: "signupStart" } },
                { kind: "Field", name: { kind: "Name", value: "signupEnd" } },
                { kind: "Field", name: { kind: "Name", value: "startDate" } },
                { kind: "Field", name: { kind: "Name", value: "endDate" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "showDateStart" },
                },
                { kind: "Field", name: { kind: "Name", value: "showDateEnd" } },
                { kind: "Field", name: { kind: "Name", value: "maxSignups" } },
                { kind: "Field", name: { kind: "Name", value: "cancelled" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "signupPossible" },
                },
                { kind: "Field", name: { kind: "Name", value: "read" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "notificationSettings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reminderFirst" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reminderSecond" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "documents" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "document" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "institution" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "videos" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "video" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "club" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "club" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "price" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "price" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "responsible" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "compactProfile" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "coverImage" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "picture" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "participants" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "Employee" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "Relative" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "Resident" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "participating" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "picture" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Picture" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "source" } },
          { kind: "Field", name: { kind: "Name", value: "inMyArchive" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "compactEmployee" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Employee" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "displayName" } },
          { kind: "Field", name: { kind: "Name", value: "displayImage" } },
          { kind: "Field", name: { kind: "Name", value: "showImage" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "compactResident" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Resident" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "displayName" } },
          { kind: "Field", name: { kind: "Name", value: "displayImage" } },
          { kind: "Field", name: { kind: "Name", value: "showImage" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "relative" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Relative" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "displayName" } },
          { kind: "Field", name: { kind: "Name", value: "displayImage" } },
          { kind: "Field", name: { kind: "Name", value: "showImage" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "mobile" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "document" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Document" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "club" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Club" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "logo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "picture" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "price" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "EventPrice" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "price" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "profileType" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "compactProfile" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Profile" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "compactEmployee" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "compactResident" },
          },
          { kind: "FragmentSpread", name: { kind: "Name", value: "relative" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "participants" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Profile" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "Employee" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "Relative" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "Resident" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "video" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Video" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "provider" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "thumbnail" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActivityFragment, unknown>;
export const CompactDocumentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "compactDocument" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Document" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompactDocumentFragment, unknown>;
export const DepartmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "department" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Department" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "institutionId" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "screenOnly" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "settings" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "meal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "daysForward" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "signup" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shift" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dayDisplay" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "daysForward" },
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "showGraphics" },
                        name: { kind: "Name", value: "gfx" },
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "showCancelled" },
                        name: { kind: "Name", value: "showCancelledFp" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "appConfig" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "modulesList" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "simpleSchedulePlan" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "startTime" },
                        name: { kind: "Name", value: "starttime" },
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "endTime" },
                        name: { kind: "Name", value: "endtime" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DepartmentFragment, unknown>;
export const InstitutionFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "institution" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Institution" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "locale" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "departments" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "department" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "settings" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "planner" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "enabledEmployees" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "enabledResidents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "useExternalMedia" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "showAllDepartments" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "department" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Department" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "institutionId" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "screenOnly" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "settings" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "meal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "daysForward" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "signup" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shift" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dayDisplay" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "daysForward" },
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "showGraphics" },
                        name: { kind: "Name", value: "gfx" },
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "showCancelled" },
                        name: { kind: "Name", value: "showCancelledFp" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "appConfig" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "modulesList" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "simpleSchedulePlan" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "startTime" },
                        name: { kind: "Name", value: "starttime" },
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "endTime" },
                        name: { kind: "Name", value: "endtime" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstitutionFragment, unknown>;
export const PictogramFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "pictogram" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Pictogram" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "source" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PictogramFragment, unknown>;
export const CompactDepartmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "compactDepartment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Department" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "institutionId" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "screenOnly" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompactDepartmentFragment, unknown>;
export const CompactInstitutionFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "compactInstitution" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Institution" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "locale" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompactInstitutionFragment, unknown>;
export const NewsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "news" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "News" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "body" } },
          { kind: "Field", name: { kind: "Name", value: "startDate" } },
          { kind: "Field", name: { kind: "Name", value: "endDate" } },
          { kind: "Field", name: { kind: "Name", value: "published" } },
          { kind: "Field", name: { kind: "Name", value: "read" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "coverImage" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "picture" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pictograms" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "pictogram" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pictures" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "picture" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "videos" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "video" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "documents" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "document" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "departments" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "compactDepartment" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "institution" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "compactInstitution" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "picture" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Picture" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "source" } },
          { kind: "Field", name: { kind: "Name", value: "inMyArchive" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "pictogram" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Pictogram" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "source" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "video" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Video" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "provider" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "thumbnail" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "document" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Document" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "compactDepartment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Department" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "institutionId" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "screenOnly" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "compactInstitution" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Institution" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "locale" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NewsFragment, unknown>;
export const NotificationSettingsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "notificationSettings" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NotificationSettings" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "reminderFirst" } },
          { kind: "Field", name: { kind: "Name", value: "reminderSecond" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "triggerWhenCancelled" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "triggerWhenTimeChange" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NotificationSettingsFragment, unknown>;
export const PlannerTaskActionResponseFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "plannerTaskActionResponse" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PlannerTaskActionResponse" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "success" } },
          { kind: "Field", name: { kind: "Name", value: "message" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PlannerTaskActionResponseFragment, unknown>;
export const PlannerTaskFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "plannerTask" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PlannerTask" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "SinglePlannerTask" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "type" },
                  name: { kind: "Name", value: "__typename" },
                },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "assignees" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "Department" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "type" },
                              name: { kind: "Name", value: "__typename" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "Employee" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "type" },
                              name: { kind: "Name", value: "__typename" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "Resident" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "type" },
                              name: { kind: "Name", value: "__typename" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "visibleForResident" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "picture" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pictogram" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subtasks" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "completed" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "completed" } },
                { kind: "Field", name: { kind: "Name", value: "startDate" } },
                { kind: "Field", name: { kind: "Name", value: "endDate" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "approvalState" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "category" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "color" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "RepeatingPlannerTask" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "type" },
                  name: { kind: "Name", value: "__typename" },
                },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "assignees" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "Department" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "type" },
                              name: { kind: "Name", value: "__typename" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "Employee" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "type" },
                              name: { kind: "Name", value: "__typename" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "Resident" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "type" },
                              name: { kind: "Name", value: "__typename" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "visibleForResident" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "picture" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pictogram" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subtasks" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "completed" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "completed" } },
                { kind: "Field", name: { kind: "Name", value: "startDate" } },
                { kind: "Field", name: { kind: "Name", value: "endDate" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "approvalState" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "category" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "color" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "repeatId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PlannerTaskFragment, unknown>;
export const DeleteActivityDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deleteActivity" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "activity" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "delete" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteActivityMutation,
  DeleteActivityMutationVariables
>;
export const SaveActivityDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "saveActivity" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "activity" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ActivityInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "activity" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "save" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "activity" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "activity" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SaveActivityMutation,
  SaveActivityMutationVariables
>;
export const SaveActivityNotificationDocumentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "saveActivityNotificationDocument" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "settings" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "NotificationSettingsInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "activity" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "saveNotificationSettings" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "settings" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "settings" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SaveActivityNotificationDocumentMutation,
  SaveActivityNotificationDocumentMutationVariables
>;
export const SaveResourceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "saveResource" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "resource" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ResourceInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "resource" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "save" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "resource" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "resource" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SaveResourceMutation,
  SaveResourceMutationVariables
>;
export const EditDepartmentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "editDepartment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "department" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DepartmentInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "editDepartment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "department" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "department" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "message" } },
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EditDepartmentMutation,
  EditDepartmentMutationVariables
>;
export const SaveEmployeeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "saveEmployee" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "employee" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "EmployeeInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "saveProfile" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "employee" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "employee" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SaveEmployeeMutation,
  SaveEmployeeMutationVariables
>;
export const DeleteNewsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deleteNews" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "news" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "delete" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteNewsMutation, DeleteNewsMutationVariables>;
export const SaveNewsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "saveNews" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "news" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "NewsInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "news" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "save" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "news" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "news" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SaveNewsMutation, SaveNewsMutationVariables>;
export const SaveProfileDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "saveProfile" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "employee" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "EmployeeInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "saveProfile" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "employee" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "employee" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SaveProfileMutation, SaveProfileMutationVariables>;
export const DeleteUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deleteUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "deleteUser" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteUserMutation, DeleteUserMutationVariables>;
export const SaveUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "saveUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "user" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UserSaveInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "saveUser" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "user" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "user" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SaveUserMutation, SaveUserMutationVariables>;
export const GetActivitiesByRangeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getActivitiesByRange" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Filter" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dateFrom" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DateTime" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dateTo" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DateTime" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "visibility" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "activity" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "getActivitiesByFilters" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "dateFrom" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dateFrom" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "dateTo" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dateTo" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "visibility" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "visibility" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "activity" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "document" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Document" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "picture" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Picture" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "source" } },
          { kind: "Field", name: { kind: "Name", value: "inMyArchive" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "club" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Club" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "logo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "picture" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "price" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "EventPrice" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "price" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "profileType" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "compactEmployee" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Employee" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "displayName" } },
          { kind: "Field", name: { kind: "Name", value: "displayImage" } },
          { kind: "Field", name: { kind: "Name", value: "showImage" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "compactResident" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Resident" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "displayName" } },
          { kind: "Field", name: { kind: "Name", value: "displayImage" } },
          { kind: "Field", name: { kind: "Name", value: "showImage" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "relative" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Relative" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "displayName" } },
          { kind: "Field", name: { kind: "Name", value: "displayImage" } },
          { kind: "Field", name: { kind: "Name", value: "showImage" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "mobile" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "compactProfile" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Profile" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "compactEmployee" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "compactResident" },
          },
          { kind: "FragmentSpread", name: { kind: "Name", value: "relative" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "participants" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Profile" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "Employee" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "Relative" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "Resident" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "video" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Video" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "provider" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "thumbnail" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "activity" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Activity" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "SingleActivity" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "signupPossible" },
                },
                { kind: "Field", name: { kind: "Name", value: "signupStart" } },
                { kind: "Field", name: { kind: "Name", value: "signupEnd" } },
                { kind: "Field", name: { kind: "Name", value: "startDate" } },
                { kind: "Field", name: { kind: "Name", value: "endDate" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "showDateStart" },
                },
                { kind: "Field", name: { kind: "Name", value: "showDateEnd" } },
                { kind: "Field", name: { kind: "Name", value: "maxSignups" } },
                { kind: "Field", name: { kind: "Name", value: "cancelled" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "signupPossible" },
                },
                { kind: "Field", name: { kind: "Name", value: "read" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "notificationSettings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reminderFirst" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reminderSecond" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "documents" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "document" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "institution" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "club" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "club" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "price" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "price" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "coverImage" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "picture" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "responsible" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "compactProfile" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "participants" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "participants" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "videos" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "video" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "RepeatingActivity" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "repeatRule" },
                  name: { kind: "Name", value: "repeat" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "rrule" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rruleText" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "signupPossible" },
                },
                { kind: "Field", name: { kind: "Name", value: "signupStart" } },
                { kind: "Field", name: { kind: "Name", value: "signupEnd" } },
                { kind: "Field", name: { kind: "Name", value: "startDate" } },
                { kind: "Field", name: { kind: "Name", value: "endDate" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "showDateStart" },
                },
                { kind: "Field", name: { kind: "Name", value: "showDateEnd" } },
                { kind: "Field", name: { kind: "Name", value: "maxSignups" } },
                { kind: "Field", name: { kind: "Name", value: "cancelled" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "signupPossible" },
                },
                { kind: "Field", name: { kind: "Name", value: "read" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "notificationSettings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reminderFirst" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reminderSecond" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "documents" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "document" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "institution" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "videos" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "video" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "club" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "club" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "price" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "price" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "responsible" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "compactProfile" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "coverImage" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "picture" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "participants" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "Employee" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "Relative" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "Resident" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "participating" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetActivitiesByRangeQuery,
  GetActivitiesByRangeQueryVariables
>;
export const GetDepartmentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getDepartment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "department" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "settings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "appConfig" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "modulesList" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "screenConfig" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "footerConfig" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "gridSize" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "modules" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "colSpan" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "rowSpan" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDepartmentQuery, GetDepartmentQueryVariables>;
export const InstitutionByIdQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "institutionByIdQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "ids" } },
          type: {
            kind: "ListType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "institutions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "get" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "ids" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "ids" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "departments" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InstitutionByIdQueryQuery,
  InstitutionByIdQueryQueryVariables
>;
export const InstitutionQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "institutionQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "ids" } },
          type: {
            kind: "ListType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "institutions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "get" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "ids" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "ids" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InstitutionQueryQuery,
  InstitutionQueryQueryVariables
>;
export const GetDepartmentListDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getDepartmentList" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "institutions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "getMyInstitution" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "departments" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "color" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDepartmentListQuery,
  GetDepartmentListQueryVariables
>;
export const InstitutionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "institutions" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "institutions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "getMyInstitution" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "departments" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "employees" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "surname" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "image" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "source",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "displayName",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "mobile" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "phone" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "alias" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "description",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "showImage" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "isSuperuser",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "username",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstitutionsQuery, InstitutionsQueryVariables>;
export const GetNewsByFiltersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getNewsByFilters" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dateFrom" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DateTime" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dateTo" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DateTime" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Filter" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "visibility" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "newsItem" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "getNewsByFilters" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "dateFrom" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dateFrom" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "dateTo" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dateTo" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "visibility" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "visibility" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "news" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "picture" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Picture" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "source" } },
          { kind: "Field", name: { kind: "Name", value: "inMyArchive" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "pictogram" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Pictogram" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "source" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "video" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Video" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "provider" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "thumbnail" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "document" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Document" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "compactDepartment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Department" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "institutionId" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "screenOnly" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "compactInstitution" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Institution" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "locale" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "news" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "News" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "body" } },
          { kind: "Field", name: { kind: "Name", value: "startDate" } },
          { kind: "Field", name: { kind: "Name", value: "endDate" } },
          { kind: "Field", name: { kind: "Name", value: "published" } },
          { kind: "Field", name: { kind: "Name", value: "read" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "coverImage" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "picture" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pictograms" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "pictogram" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pictures" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "picture" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "videos" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "video" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "documents" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "document" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "departments" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "compactDepartment" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "institution" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "compactInstitution" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetNewsByFiltersQuery,
  GetNewsByFiltersQueryVariables
>;
export const NewsEntryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "NewsEntry" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "newsEntry" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "news" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "picture" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Picture" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "source" } },
          { kind: "Field", name: { kind: "Name", value: "inMyArchive" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "pictogram" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Pictogram" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "source" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "video" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Video" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "provider" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "thumbnail" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "document" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Document" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "compactDepartment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Department" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "institutionId" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "screenOnly" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "compactInstitution" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Institution" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "locale" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "news" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "News" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "body" } },
          { kind: "Field", name: { kind: "Name", value: "startDate" } },
          { kind: "Field", name: { kind: "Name", value: "endDate" } },
          { kind: "Field", name: { kind: "Name", value: "published" } },
          { kind: "Field", name: { kind: "Name", value: "read" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "coverImage" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "picture" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pictograms" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "pictogram" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pictures" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "picture" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "videos" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "video" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "documents" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "document" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "departments" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "compactDepartment" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "institution" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "compactInstitution" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NewsEntryQuery, NewsEntryQueryVariables>;
export const AuditLogQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AuditLogQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filters" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "AuditLogFilter" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "auditLog" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "getByFilters" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filters" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filters" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "created" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "entityId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "entityType" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "event" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "profileId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "profileType" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuditLogQueryQuery, AuditLogQueryQueryVariables>;
export const CreatePlannerTaskDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreatePlannerTask" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "plannerTask" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "PlannerTaskCreateInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createPlannerTask" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "plannerTask" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "plannerTask" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "plannerTaskActionResponse" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "plannerTaskActionResponse" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PlannerTaskActionResponse" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "success" } },
          { kind: "Field", name: { kind: "Name", value: "message" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePlannerTaskMutation,
  CreatePlannerTaskMutationVariables
>;
export const DeleteTaskDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteTask" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DeletePlannerTaskInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deletePlannerTask" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "message" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteTaskMutation, DeleteTaskMutationVariables>;
export const EditPlannerTaskDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "EditPlannerTask" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "plannerTaskId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "plannerTask" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "PlannerTaskEditInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "editPlannerTask" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "plannerTaskId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "plannerTaskId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "plannerTask" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "plannerTask" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "plannerTaskActionResponse" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "plannerTaskActionResponse" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PlannerTaskActionResponse" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "success" } },
          { kind: "Field", name: { kind: "Name", value: "message" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EditPlannerTaskMutation,
  EditPlannerTaskMutationVariables
>;
export const EntitiesAvailabilityDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "entitiesAvailability" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Filter" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dateFrom" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dateTo" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "timezone" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "TimeZone" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "meetingRange" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filter" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "dateFrom" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dateFrom" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "dateTo" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dateTo" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "timezone" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "timezone" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "SingleMeeting" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "startDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "endDate" },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "RepeatingMeeting" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "startDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "endDate" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "activityRange" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filter" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "dateFrom" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dateFrom" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "dateTo" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dateTo" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "timezone" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "timezone" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "SingleActivity" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "startDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "endDate" },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "RepeatingActivity" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "startDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "endDate" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "mealRange" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filter" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "dateFrom" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dateFrom" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "dateTo" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dateTo" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "timezone" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "timezone" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "startDate" } },
                { kind: "Field", name: { kind: "Name", value: "endDate" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EntitiesAvailabilityQuery,
  EntitiesAvailabilityQueryVariables
>;
export const GetActorsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetActors" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Filter" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "profiles" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filter" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "Employee" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayImage" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "settings" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "planner" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "assignment" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "inbox",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "Resident" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayImage" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "settings" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "planner" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "assignment" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "inbox",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "institution" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "departments" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "color" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "screenOnly" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetActorsQuery, GetActorsQueryVariables>;
export const GetPlannerTasksDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getPlannerTasks" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dateFrom" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DateTime" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dateTo" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DateTime" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "planner" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "getTasksByFilters" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "dateFrom" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dateFrom" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "dateTo" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dateTo" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "plannerTask" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "plannerTask" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PlannerTask" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "SinglePlannerTask" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "type" },
                  name: { kind: "Name", value: "__typename" },
                },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "assignees" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "Department" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "type" },
                              name: { kind: "Name", value: "__typename" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "Employee" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "type" },
                              name: { kind: "Name", value: "__typename" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "Resident" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "type" },
                              name: { kind: "Name", value: "__typename" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "visibleForResident" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "picture" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pictogram" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subtasks" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "completed" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "completed" } },
                { kind: "Field", name: { kind: "Name", value: "startDate" } },
                { kind: "Field", name: { kind: "Name", value: "endDate" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "approvalState" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "category" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "color" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "RepeatingPlannerTask" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "type" },
                  name: { kind: "Name", value: "__typename" },
                },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "assignees" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "Department" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "type" },
                              name: { kind: "Name", value: "__typename" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "Employee" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "type" },
                              name: { kind: "Name", value: "__typename" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "Resident" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "type" },
                              name: { kind: "Name", value: "__typename" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "visibleForResident" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "picture" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pictogram" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subtasks" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "completed" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "completed" } },
                { kind: "Field", name: { kind: "Name", value: "startDate" } },
                { kind: "Field", name: { kind: "Name", value: "endDate" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "approvalState" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "category" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "color" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "repeatId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPlannerTasksQuery,
  GetPlannerTasksQueryVariables
>;
export const PlannerCategoriesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PlannerCategories" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "plannerCategories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "color" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PlannerCategoriesQuery,
  PlannerCategoriesQueryVariables
>;
export const ScheduleAvailableEmployeesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "scheduleAvailableEmployees" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Filter" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dateFrom" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DateTime" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dateTo" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DateTime" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "scheduleAvailableEmployees" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filter" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "from" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dateFrom" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "to" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dateTo" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "employeeId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unavailableFrom" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unavailableTo" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unavailableReason" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ScheduleAvailableEmployeesQuery,
  ScheduleAvailableEmployeesQueryVariables
>;
export const SettingsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "settings" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "settings" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "institutions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "settings" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "vismaGatWs" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "planner" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "manager" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "showOverdue",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SettingsQuery, SettingsQueryVariables>;
export const GetProfilesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getProfiles" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Filter" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "profiles" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filter" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "Employee" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "image" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "source" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "departments" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "color" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "Resident" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "image" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "source" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "departments" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "color" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProfilesQuery, GetProfilesQueryVariables>;
export const DeleteUserByUsernameDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deleteUserByUsername" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "message" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteUserByUsernameMutation,
  DeleteUserByUsernameMutationVariables
>;
export const GetSelfQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getSelfQuery" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "self" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "get" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "profiles" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "Employee" },
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "institution",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "notificationSettings",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "activity",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "FragmentSpread",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "notificationSettings",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "notificationSettings" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "NotificationSettings" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "reminderFirst" } },
          { kind: "Field", name: { kind: "Name", value: "reminderSecond" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "triggerWhenCancelled" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "triggerWhenTimeChange" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSelfQueryQuery, GetSelfQueryQueryVariables>;
