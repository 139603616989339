import { graphqlClient } from "@/apollo";
import { type InstitutionsEmployeeFragment } from "@/graphql";
import {
  InstitutionsDocument,
  SaveEmployeeDocument,
  type EmployeeInput,
} from "@/graphql/generated-types";

const getEmployees = async (): Promise<InstitutionsEmployeeFragment[]> => {
  const response = await graphqlClient.query({
    query: InstitutionsDocument,
    fetchPolicy: "no-cache",
  });

  if (response.data.institutions?.getMyInstitution.departments) {
    const [department] =
      response.data.institutions.getMyInstitution.departments;
    return department.employees as InstitutionsEmployeeFragment[];
  }

  throw new Error("Query error on getEmployees");
};

const save = async (employee: EmployeeInput) => {
  const response = await graphqlClient.query({
    query: SaveEmployeeDocument,
    variables: {
      employee,
    },
    fetchPolicy: "no-cache",
  });

  if (response.data.user?.saveProfile?.success) {
    return;
  }

  throw new Error(`An error occurred on employee save`);
};

export default {
  getEmployees,
  save,
};
