import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/stores/auth.module";
import NotFound from "../views/NotFound.vue";

const routes = [
  {
    name: "planner/edit-task",
    // There appears to be a bug, probably in pathToRegexp, in that the expression
    // \\d+\\|?\\d* will correctly match our 123|456 ids and convert them to param,
    // but will not insert it into the URL, giving problems with back/forward buttons.
    // For now we will leave the id param with no pattern and do the validation elsewhere.
    path: "/planner/edit-task/:id?",
    component: () => import("@/views/planner/EditTask.vue"),
  },
  {
    path: "/planner",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].ts) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("@/views/Planner.vue"),
    children: [
      {
        name: "planner/day-view",
        path: "",
        component: () => import("@/views/planner/DayView.vue"),
      },
      {
        name: "planner/day-view/specific",
        path: "day-view",
        component: () => import("@/views/planner/DayView.vue"),
      },
      {
        name: "planner/search",
        path: "search",
        component: () => import("@/views/planner/SearchResults.vue"),
      },
      {
        name: "planner/overdue/specific",
        path: "overdue",
        component: () => import("@/views/planner/Overdue.vue"),
      },
      {
        name: "planner/week-view/specific",
        path: "week-view",
        component: () => import("@/views/planner/WeekView.vue"),
      },
    ],
  },
  {
    name: "scripts",
    path: "/scripts",
    component: () => import("@/views/scripts/Scripts.vue"),
  },
  {
    name: "screen-configs-new",
    path: "/screen-configs-new",
    component: () => import("@/views/screenAndAppConfig/ScreenConfig.vue"),
  },
  {
    name: "app-configs",
    path: "/app-configs",
    component: () => import("@/views/screenAndAppConfig/AppConfig.vue"),
  },
  {
    name: "screenConfig",
    path: "/screenConfig",
    component: () => import("@/views/screenAndAppConfig/ScreenConfig.vue"),
  },
  {
    name: "home",
    path: "/",
    component: () => import("@/views/News.vue"),
  },
  {
    name: "callback",
    path: "/callback",
    component: () => import("@/views/Callback.vue"),
  },
  {
    name: "news",
    path: "/news",
    component: () => import("@/views/News.vue"),
  },
  {
    name: "activities",
    path: "/activities",
    component: () => import("@/views/Activities.vue"),
  },
  {
    name: "employees",
    path: "/employees",
    component: () => import("@/views/Employees.vue"),
  },
  {
    name: "relativeProfileCreation",
    path: "/relative-profile-creation",
    component: () => import("@/views/RelativeProfileCreationView.vue"),
  },
  { path: "/:pathMatch(.*)", component: NotFound },
];

const devRoutes = [
  {
    name: "Sandbox",
    path: "/sandbox",
    component: () => import("@/views/sandbox/Sandbox.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [...routes, ...(import.meta.env.DEV ? devRoutes : [])],
});

router.beforeEach((to: any, from: any, next: any) => {
  const authStore = useAuthStore();
  if (
    !window?.$features?.iframe &&
    authStore.login === false &&
    to.name !== "callback" &&
    to.name !== "relativeProfileCreation"
  ) {
    authStore.redirectLogin();
  } else {
    // Everything is fine!
    next();
  }
});

export default router;
