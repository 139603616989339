import { APP_API_URL } from "../consts";
import { graphqlClient } from "@/apollo";
import {
  DeleteUserDocument,
  type Employee,
  GetSelfQueryDocument,
  SaveUserDocument,
  type UserSaveInput,
} from "@/graphql/generated-types";

const getToken = async (jwt: any) => {
  const response = await window.fetch(`${APP_API_URL}/user/accesstoken`, {
    method: "GET",
    headers: {
      Authorization: jwt,
    },
  });

  if (response.status === 200) {
    return await response.json();
  }

  throw new Error(`Auth token error.`);
};

const getSelf = async (): Promise<Employee> => {
  const response = await graphqlClient.query({
    query: GetSelfQueryDocument,
    variables: {},
    fetchPolicy: "no-cache",
  });

  if (response.data.self?.get.profiles) {
    const [firstProfile] = response.data.self.get.profiles;
    return firstProfile as Employee;
  }

  throw new Error("Get self query error.");
};

const save = async (user: UserSaveInput) => {
  const response = await graphqlClient.query({
    query: SaveUserDocument,
    variables: {
      user,
    },
    fetchPolicy: "no-cache",
  });

  if (response.data.user?.saveUser?.success) {
    return response.data.user.saveUser.id;
  }

  throw new Error("An error occurred on creating user");
};

const deleteItem = async (id: any) => {
  const response = await graphqlClient.query({
    query: DeleteUserDocument,
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
  });

  if (response.data.user?.deleteUser?.success) {
    return response.data.user.deleteUser.success;
  }

  throw new Error(`An error occurred on deleting user.`);
};

export default {
  getToken,
  getSelf,
  save,
  deleteItem,
};
